import React from "react";
import { useTranslation } from "react-i18next";
import { CModal } from "../../../../components/atoms";
import { Constants, Images } from "../../../../config";
import IDProfileContainer from "./IDProfileContainer";

const IDProfileModal = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const onButtonAction = (data) => {
    if (props.onButtonAction) {
      props.onButtonAction(data);
    }
    if (ref) {
      ref.current.hideModal();
    }
  };
  return (
    <CModal
      ref={ref}
      {...props}
      title={t("key_id_profile")}
      icon={Images.iconBarcode}>
      <IDProfileContainer {...props} onButtonAction={onButtonAction} />
    </CModal>
  );
});
export default IDProfileModal;
