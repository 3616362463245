import React, { useRef } from "react";
import { StyleSheet, View, Text } from "react-native-web";
import { useTranslation } from "react-i18next";
import { Fonts, Colors, Images } from "../../../../config";
import { CImage } from "../../../../components/atoms";
import { getClass } from "../../../../helpers/formatHelpers";

const OfficialBadgeContainer = (props) => {
  const { t } = useTranslation();
  const { isMobileVersion } = props;

  return (
    <View style={[styles.container, props.style]}>
      <View
        style={{
          backgroundColor: getClass("bg-gray1"),
          justifyContent: "center",
          alignItems: "center",
          paddingHorizontal: 22,
          marginBottom: 14,
          borderRadius: 18,
          width: "100%",
          height: "100%",
        }}>
        <CImage
          source={Images.iconVerifiedBadgeMedium}
          alt="verified account"
          width={isMobileVersion ? 24 : 35}
          height={isMobileVersion ? 24 : 35}
          layout="fixed"
        />
        <Text
          style={{
            fontFamily: Fonts.primaryBold,
            fontSize: isMobileVersion ? 16 : 20,
            lineHeight: isMobileVersion ? 20 : 24,
            color: Colors.white,
            marginVertical: isMobileVersion ? 9 : 14,
            textAlign: "center",
          }}>
          Official Account
        </Text>
        <Text
          style={{
            fontFamily: Fonts.primaryRegular,
            fontSize: isMobileVersion ? 12 : 16,
            lineHeight: isMobileVersion ? 16 : 18,
            color: Colors.white80,
            textAlign: "center",
          }}>
          This is an official account verified by{" "}
          {process.env.NEXT_PUBLIC_APP_NAME}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    alignSelf: "stretch",
  },
});

export default OfficialBadgeContainer;
