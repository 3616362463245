import React, { useRef } from "react";
import { StyleSheet, View, Text } from "react-native-web";
import { useTranslation } from "react-i18next";
import { Fonts, Colors, Constants } from "../../../../config";
import { IconizedButton } from "../../../../components/atoms";
import { getClass } from "../../../../helpers/formatHelpers";
//import Clipboard from "@react-native-clipboard/clipboard";
const IDProfileContainer = (props) => {
  const { t } = useTranslation();
  const { item, isMobileVersion } = props;
  const textAreaRef = useRef(null);

  let idProfileText = "";
  if (item.globalId) {
    idProfileText = item.globalId;
  } else if (item.profileId) {
    idProfileText = item.profileId;
  }
  const copyTextToClipboard = async (e) => {
    textAreaRef.current.select();
    document.execCommand("copy");
    // if ("clipboard" in navigator) {
    //   return await navigator.clipboard.writeText(text);
    // } else {
    //   return document.execCommand("copy", true, text);
    // }
  };
  const onButtonAction = (actionType, e) => {
    if (props.onButtonAction) {
      props.onButtonAction({
        modalType: Constants.profileModalType.PROFILE_MODAL_ID_PROFILE,
        actionType: actionType,
      });
    }
    if (actionType == Constants.actionType.ACTION_COPY) {
      copyTextToClipboard(e);
    }
  };
  return (
    <View style={[styles.container, props.style]}>
      <View style={styles.contentContainer}>
        <Text style={[styles.text, isMobileVersion ? { fontSize: 12 } : {}]}>
          {idProfileText}
        </Text>
      </View>
      <View
        style={[styles.footerButton, isMobileVersion ? { height: 72 } : {}]}>
        <View style={styles.footerItem}>
          <IconizedButton
            buttonTitle={t("key_profile_button_cancel")}
            style={[
              styles.buttonStyle,
              {
                borderColor: getClass("border-black"),
              },
            ]}
            borderedTextColor={getClass("text-white")}
            bordered
            fontSize={isMobileVersion ? 12 : 16}
            onPress={(e) =>
              onButtonAction(Constants.actionType.ACTION_CANCEL, e)
            }
            isMobileVersion={isMobileVersion}
          />
        </View>
        <View style={styles.footerItem}>
          <IconizedButton
            buttonTitle={t("key_profile_button_copy")}
            style={styles.buttonStyle}
            filled
            fontSize={isMobileVersion ? 12 : 16}
            onPress={(e) => onButtonAction(Constants.actionType.ACTION_COPY, e)}
            isMobileVersion={isMobileVersion}
            filledColor={
              process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia"
                ? Colors.zooBlue
                : process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld"
                ? Colors.prefabBlack
                : process.env.NEXT_PUBLIC_APP_NAME == "Parkful"
                ? Colors.parkfulPink
                : process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club" ||
                  process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub"
                ? Colors.resortPink
                : process.env.NEXT_PUBLIC_APP_NAME == "SpaClub"
                ? Colors.spaBlue
                : process.env.NEXT_PUBLIC_APP_NAME == "Beachful"
                ? Colors.beachPink
                : null
            }
            filledTextColor={
              process.env.NEXT_PUBLIC_APP_NAME == "Zoopedia" ||
              process.env.NEXT_PUBLIC_APP_NAME == "PrefabWorld" ||
              process.env.NEXT_PUBLIC_APP_NAME == "Parkful" ||
              process.env.NEXT_PUBLIC_APP_NAME == "LuxResort Club" ||
              process.env.NEXT_PUBLIC_APP_NAME == "RooftopClub" ||
              process.env.NEXT_PUBLIC_APP_NAME == "SpaClub" ||
              process.env.NEXT_PUBLIC_APP_NAME == "Beachful"
                ? Colors.white
                : null
            }
          />
        </View>
      </View>
      <textarea
        ref={textAreaRef}
        value={idProfileText}
        className="opacity-0 absolute top-0 left-0"
        readOnly={true}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    alignSelf: "stretch",
  },
  contentContainer: {
    paddingVertical: 16,
    alignSelf: "stretch",
  },
  text: {
    textAlign: "center",
    fontFamily: Fonts.primaryRegular,
    fontSize: 16,
    lineHeight: 14,
    color: getClass("text-white"),
  },
  footerButton: {
    borderTopColor: getClass("border-tabBottomColor"),
    borderTopWidth: 1,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 10,
    paddingTop: 16,
    marginHorizontal: -16,
    marginBottom: -16,
    marginTop: 4,
    alignSelf: "stretch",
    height: 90,
  },
  footerItem: {
    paddingHorizontal: 6,
    flex: 1,
  },
  buttonStyle: {
    marginBottom: 16,
  },
});

export default IDProfileContainer;
