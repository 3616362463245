import React, { useEffect, useState } from "react";
import { Constants, Images } from "../../../config";
import { View } from "react-native-web";
import { BackButton, CImage } from "../../../components/atoms";
import Image from "next/image";
import moment from "moment";
import { useSwipeable } from "react-swipeable";
import dynamic from "next/dynamic";

const PopupMenuListModal = dynamic(
  () => import("../../../components/molecules/PopupMenuListModal"),
  {
    ssr: false,
  }
);

const ProfileHeader = (props) => {
  const {
    customNavBar,
    isShowMenu,
    isMobileVersion,
    activeIndex,
    setActiveIndex,
    category,
  } = props;
  const showDefaultProfileHeader = !customNavBar;
  const handlers = useSwipeable({
    onSwipedLeft: (eventData) => {
      if (activeIndex + 1 > photos.length - 1) {
        setActiveIndex(0);
      } else {
        setActiveIndex(activeIndex + 1);
      }
    },
    onSwipedRight: (eventData) => {
      if (activeIndex - 1 < 0) {
        setActiveIndex(photos.length - 1);
      } else {
        setActiveIndex(activeIndex - 1);
      }
    },
  });

  let photos = props.photos ? props.photos : [];

  if (!Array.isArray(photos)) {
    photos = JSON.parse(photos);
  }
  const onPressMenuItem = (item) => {
    if (props.onPressMenuItem) {
      props.onPressMenuItem(item);
    }
    props.onChangeIsShown(false);
  };
  const backgroundImage =
    photos?.length > 0 ? photos[activeIndex].url : "Local:imagePlaceholder";

  return (
    <View style={[props.style]}>
      <div {...handlers}>
        <CImage
          priority={true}
          objectFit={"fixed"}
          imgUrl={backgroundImage}
          alt={
            props?.event
              ? `${props?.event?.title} ${moment(
                  props?.event?.startDate
                ).format("YYYY")} ${
                  category === Constants.categoryType.CATEGORY_ARTIST
                    ? "Artist"
                    : category === Constants.categoryType.CATEGORY_VENUE
                    ? "Venue"
                    : category === Constants.categoryType.CATEGORY_ROOFTOP
                    ? "Rooftop"
                    : category === Constants.categoryType.CATEGORY_SPA
                    ? "Spa"
                    : category ===
                      Constants.categoryType.CATEGORY_VENUE_BEACHFUL
                    ? "Beach Club"
                    : category ===
                      Constants.categoryType.CATEGORY_PREFAB_COMPANY
                    ? "Company"
                    : category === Constants.categoryType.CATEGORY_PREFAB_HOMES
                    ? "Home"
                    : category === Constants.categoryType.CATEGORY_ZOO
                    ? "Zoo/animal park"
                    : category === Constants.categoryType.CATEGORY_ANIMAL
                    ? "Animal"
                    : category === Constants.categoryType.CATEGORY_PARKFUL
                    ? "Park"
                    : category === Constants.categoryType.CATEGORY_CASINO
                    ? "Casino"
                    : category === Constants.categoryType.CATEGORY_CASTLE
                    ? "Castle"
                    : category === Constants.categoryType.CATEGORY_RESORT
                    ? "Resort"
                    : category === Constants.categoryType.CATEGORY_APARTHOTEL
                    ? "Aparthotel"
                    : category ===
                      Constants.categoryType.CATEGORY_VENUE_COCKTAIL
                    ? "Bar"
                    : category === Constants.categoryType.CATEGORY_EVENT
                    ? "Event"
                    : "Festival"
                }`
              : props?.artist
              ? `${props?.artist?.displayName} ${
                  category === Constants.categoryType.CATEGORY_ARTIST
                    ? "Artist"
                    : category === Constants.categoryType.CATEGORY_VENUE
                    ? "Venue"
                    : category === Constants.categoryType.CATEGORY_ROOFTOP
                    ? "Rooftop"
                    : category === Constants.categoryType.CATEGORY_SPA
                    ? "Spa"
                    : category ===
                      Constants.categoryType.CATEGORY_VENUE_BEACHFUL
                    ? "Beach Club"
                    : category ===
                      Constants.categoryType.CATEGORY_PREFAB_COMPANY
                    ? "Company"
                    : category === Constants.categoryType.CATEGORY_PREFAB_HOMES
                    ? "Home"
                    : category === Constants.categoryType.CATEGORY_ZOO
                    ? "Zoo/animal park"
                    : category === Constants.categoryType.CATEGORY_ANIMAL
                    ? "Animal"
                    : category === Constants.categoryType.CATEGORY_PARKFUL
                    ? "Park"
                    : category === Constants.categoryType.CATEGORY_CASINO
                    ? "Casino"
                    : category === Constants.categoryType.CATEGORY_CASTLE
                    ? "Castle"
                    : category === Constants.categoryType.CATEGORY_RESORT
                    ? "Resort"
                    : category === Constants.categoryType.CATEGORY_APARTHOTEL
                    ? "Aparthotel"
                    : category ===
                      Constants.categoryType.CATEGORY_VENUE_COCKTAIL
                    ? "Bar"
                    : category === Constants.categoryType.CATEGORY_EVENT
                    ? "Event"
                    : "Festival"
                }`
              : `${props?.venue?.nickname} ${
                  category === Constants.categoryType.CATEGORY_ARTIST
                    ? "Artist"
                    : category === Constants.categoryType.CATEGORY_VENUE
                    ? "Venue"
                    : category === Constants.categoryType.CATEGORY_ROOFTOP
                    ? "Rooftop"
                    : category === Constants.categoryType.CATEGORY_SPA
                    ? "Spa"
                    : category ===
                      Constants.categoryType.CATEGORY_VENUE_BEACHFUL
                    ? "Beach Club"
                    : category ===
                      Constants.categoryType.CATEGORY_PREFAB_COMPANY
                    ? "Company"
                    : category === Constants.categoryType.CATEGORY_PREFAB_HOMES
                    ? "Home"
                    : category === Constants.categoryType.CATEGORY_ZOO
                    ? "Zoos"
                    : category === Constants.categoryType.CATEGORY_ANIMAL
                    ? "Animals"
                    : category === Constants.categoryType.CATEGORY_PARKFUL
                    ? "Parks"
                    : category === Constants.categoryType.CATEGORY_CASINO
                    ? "Casinos"
                    : category === Constants.categoryType.CATEGORY_CASTLE
                    ? "Castles"
                    : category === Constants.categoryType.CATEGORY_RESORT
                    ? "Resorts"
                    : category === Constants.categoryType.CATEGORY_APARTHOTEL
                    ? "Aparthotels"
                    : category ===
                      Constants.categoryType.CATEGORY_VENUE_COCKTAIL
                    ? "Bar"
                    : category === Constants.categoryType.CATEGORY_EVENT
                    ? "Event"
                    : "Festival"
                }`
          }
        />
      </div>
      {showDefaultProfileHeader && isMobileVersion && <BackButton />}
      {showDefaultProfileHeader && isMobileVersion && (
        <div
          className="flex absolute top-[16px] right-[16px] w-[30px] h-[30px] items-center justify-center bg-black10 rounded-full cursor-pointer"
          onClick={() => props.onChangeIsShown(true)}>
          <Image
            src={Images.iconMoreWhite}
            width={20}
            height={20}
            alt="icon more menu"
          />
        </div>
      )}
      {photos.length > 1 && (
        <div
          className={`absolute z-10 w-full flex flex-row justify-center ${
            isMobileVersion ? "pt-[26px]" : "pt-[39px]"
          }`}>
          {photos.map((item, index) => (
            <div
              className={`h-[6px] w-[6px] mr-[8px] rounded-full ${
                index === activeIndex ? "bg-white" : "bg-black25"
              }`}
              key={index}>
              &nbsp;
            </div>
          ))}
        </div>
      )}
      {!isMobileVersion && photos.length > 1 && (
        <div className="absolute z-10 h-full w-full flex flex-row justify-between items-center px-[15px]">
          <div
            className="cursor-pointer"
            onClick={() => {
              if (activeIndex - 1 < 0) {
                setActiveIndex(photos.length - 1);
              } else {
                setActiveIndex(activeIndex - 1);
              }
            }}>
            <img
              src="/icons/ic_arrow_left_black_40.png"
              width="45"
              height="45"
              alt="arrow"
            />
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              if (activeIndex + 1 > photos.length - 1) {
                setActiveIndex(0);
              } else {
                setActiveIndex(activeIndex + 1);
              }
            }}>
            <img
              src="/icons/ic_arrow_right_black_40.png"
              width="45"
              height="45"
              alt="arrow"
            />
          </div>
        </div>
      )}
      {customNavBar}
      {isShowMenu && (
        <PopupMenuListModal
          isVisible={isShowMenu}
          onClose={() => {
            props.onChangeIsShown(false);
          }}
          onPressItem={onPressMenuItem}
          items={props.items}
          isMobileVersion={isMobileVersion}
          category={props.category}
        />
      )}
    </View>
  );
};

export default ProfileHeader;
