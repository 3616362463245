import React from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native-web";
import { useTranslation } from "react-i18next";
import { Fonts, Colors, Constants, Images } from "../../../../config";
import { CImage, IconizedButton } from "../../../../components/atoms";
import Link from "next/link";
import { getClass } from "../../../../helpers/formatHelpers";
// import { chatClient } from '../../../../helpers/useChatClient'

const BookingContainer = (props) => {
  const { t } = useTranslation();
  const { isMobileVersion } = props;
  if (!props.contact) return null;
  const {
    email,
    phone,
    website,
    instagramUrl,
    facebookUrl,
    twitterUrl,
    youtubeUrl,
    spotifyUrl,
  } = props.contact;
  const {
    id,
    profileId,
    title,
    nickname,
    socialFacebook,
    socialInstagram,
    socialTwitter,
    socialSpotify,
    socialYoutube,
    socialTiktok,
    socialIds,

    // for artist
    youtube,
    instagram,
    tiktok,
    facebook,
    spotify,
    twitter,
    privacy,

    contactWebsite_follow_link,
  } = props.item;

  // const channelId = `from_${user?.user?.id}_to_${profileId || id}`
  // const channel = chatClient.userID
  //   ? chatClient.channel('messaging', channelId, {
  //       // name: title || nickname,
  //       members: [
  //         user?.user?.id?.toString(),
  //         profileId?.toString() || id?.toString(),
  //       ],
  //     })
  //   : null

  const getURL = (socialType, username) => {
    let prefix = "";
    if (socialType == Constants.socialType.SOCIAL_TYPE_FACEBOOK) {
      prefix = "https://facebook.com/";
    } else if (socialType == Constants.socialType.SOCIAL_TYPE_INSTAGRAM) {
      prefix = "https://instagram.com/";
    } else if (socialType == Constants.socialType.SOCIAL_TYPE_TWITTER) {
      prefix = "https://twitter.com/";
    } else if (socialType == Constants.socialType.SOCIAL_TYPE_YOUTUBE) {
      prefix = "https://youtube.com/";
    }
    return username ? prefix + username : null;
  };

  const socialLinks = [
    {
      icon: Images.iconInstagramLinkCircle,
      url:
        instagramUrl ||
        socialInstagram ||
        instagram ||
        getURL(
          Constants.socialType.SOCIAL_TYPE_INSTAGRAM,
          socialIds?.find(
            (i) => i.socialType === Constants.socialType.SOCIAL_TYPE_INSTAGRAM
          )?.username
        ),
    },
    {
      icon: Images.iconFacebookLinkCircle,
      url:
        facebookUrl ||
        socialFacebook ||
        facebook ||
        getURL(
          Constants.socialType.SOCIAL_TYPE_FACEBOOK,
          socialIds?.find(
            (i) => i.socialType === Constants.socialType.SOCIAL_TYPE_FACEBOOK
          )?.username
        ),
    },
    {
      icon: Images.iconTwitterLinkCircle,
      url:
        twitterUrl ||
        socialTwitter ||
        twitter ||
        getURL(
          Constants.socialType.SOCIAL_TYPE_TWITTER,
          socialIds?.find(
            (i) => i.socialType === Constants.socialType.SOCIAL_TYPE_TWITTER
          )?.username
        ),
    },
    {
      icon: Images.iconYoutubeLinkCircle,
      url:
        youtubeUrl ||
        socialYoutube ||
        youtube ||
        getURL(
          Constants.socialType.SOCIAL_TYPE_YOUTUBE,
          socialIds?.find(
            (i) => i.socialType === Constants.socialType.SOCIAL_TYPE_YOUTUBE
          )?.username
        ),
    },
    {
      icon: Images.iconSpotifyLinkCircle,
      url:
        spotifyUrl ||
        socialSpotify ||
        spotify ||
        getURL(
          Constants.socialType.SOCIAL_TYPE_SPOTIFY,
          socialIds?.find(
            (i) => i.socialType === Constants.socialType.SOCIAL_TYPE_SPOTIFY
          )?.username
        ),
    },
  ];
  const activeSocialLinks = [];
  socialLinks.forEach((item) => {
    if (item && item.url != null && item.url != "") {
      activeSocialLinks.push(item);
    }
  });
  const onButtonAction = (actionType) => {
    if (props.onButtonAction) {
      props.onButtonAction({
        modalType: Constants.profileModalType.PROFILE_MODAL_CONTACT,
        actionType: actionType,
      });
    }
  };
  const renderEmailItem = (icon, label, style) => {
    if (!label) return null;
    return (
      <TouchableOpacity
        style={[styles.rowContainer, style]}
        onPress={() => {
          onOpenLink(`mailto:${label}`);
          onButtonAction(Constants.actionType.ACTION_CLOSE);
        }}>
        <CImage
          width={isMobileVersion ? 16 : 20}
          height={isMobileVersion ? 16 : 20}
          layout="fixed"
          source={icon}
          style={styles.icon}
          resizeMode="contain"
          alt="email"
        />
        <Text
          style={[
            styles.label,
            isMobileVersion ? { fontSize: 12 } : {},
            { marginLeft: 10 },
          ]}>
          {label}
        </Text>
      </TouchableOpacity>
    );
  };
  const renderPhoneItem = (icon, label, style) => {
    if (!label) return null;
    return (
      <TouchableOpacity
        style={[styles.rowContainer, style]}
        onPress={() => {
          onOpenLink(`tel:${label?.replace(/ /g, "")}`);
          onButtonAction(Constants.actionType.ACTION_CLOSE);
        }}>
        <CImage
          width={isMobileVersion ? 16 : 20}
          height={isMobileVersion ? 16 : 20}
          layout="fixed"
          source={icon}
          style={styles.icon}
          resizeMode="contain"
          alt="phone"
        />
        <Text
          style={[
            styles.label,
            isMobileVersion ? { fontSize: 12 } : {},
            { marginLeft: 6 },
          ]}>
          {label?.replace(/ /g, "")}
        </Text>
      </TouchableOpacity>
    );
  };
  const renderWebsiteItem = (icon, label, style) => {
    if (!label) return null;
    return (
      <View
        style={[styles.rowContainer, style]}
        onPress={() => {
          onOpenLink(label);
          onButtonAction(Constants.actionType.ACTION_CLOSE);
        }}>
        <CImage
          width={isMobileVersion ? 16 : 20}
          height={isMobileVersion ? 16 : 20}
          layout="fixed"
          source={icon}
          style={styles.icon}
          resizeMode="contain"
          alt="website"
        />
        <Text
          style={[
            styles.label,
            isMobileVersion ? { fontSize: 12 } : {},
            { marginLeft: 6, flex: 1 },
          ]}>
          <Link href={label}>
            {contactWebsite_follow_link ? (
              <a target="_blank">
                {label.length > 40 ? label.substring(0, 40) + "..." : label}
              </a>
            ) : (
              <a rel="nofollow" target="_blank">
                {label.length > 40 ? label.substring(0, 40) + "..." : label}
              </a>
            )}
          </Link>
        </Text>
      </View>
    );
  };
  const onOpenLink = (url) => {
    const win = window.open(url, "_blank");
    win.focus();
  };
  const renderSocialLinkItem = (item, index, totalCount) => {
    const { url, icon } = item;
    const isLast = index == totalCount - 1;
    return (
      <TouchableOpacity
        style={[styles.socialLinkIcon, { marginRight: isLast ? 0 : 18 }]}
        key={index + "social-link"}
        onPress={() => {
          onOpenLink(url);
          onButtonAction(Constants.actionType.ACTION_CLOSE);
        }}>
        <CImage
          width={40}
          height={40}
          layout="fixed"
          source={icon}
          style={styles.socialLinkIcon}
          resizeMode="contain"
          alt="social link"
        />
      </TouchableOpacity>
    );
  };
  const renderSocialItems = (socialItems) => {
    const totalCount = socialItems.length;
    return socialItems.map((socialItem, index) => {
      return renderSocialLinkItem(socialItem, index, totalCount);
    });
  };
  return (
    <View style={[styles.container, props.style]}>
      {privacy === Constants.privacyType.private ? (
        <View style={{ padding: 20, alignItems: "center" }}>
          <Text
            style={{
              color: Colors.grayText,
              fontFamily: Fonts.primaryRegular,
              fontSize: 16,
              lineHeight: 20,
            }}>
            {t("key_private_mode_text")}
          </Text>
        </View>
      ) : (
        <View style={styles.contentContainer}>
          {!email && !phone && !website && activeSocialLinks.length === 0 && (
            <Text
              style={[
                styles.emptyText,
                isMobileVersion ? { fontSize: 12 } : {},
              ]}>
              {t("key_contact_empty")}
            </Text>
          )}
          {renderEmailItem(Images.iconEmailColored, email)}
          {renderPhoneItem(Images.iconPhoneColored, phone, {
            marginTop: 16,
          })}
          {renderWebsiteItem(
            Images.iconWebsiteColored,
            website && !website?.includes("http")
              ? `https://${website}`
              : website,
            {
              marginTop: 16,
            }
          )}
        </View>
      )}
      {/* {props.category === Constants.categoryType.CATEGORY_OTHER && (
        <IconizedButton
          buttonTitle={t('key_button_chat')}
          style={[styles.buttonStyle, { marginBottom: 16 }]}
          filled
          onPress={async () => {
            try {
              if (channel) {
                await channel?.create()
              }
              navigation.navigate('ChannelScreen', { channel })
              onButtonAction(Constants.actionType.ACTION_CLOSE)
            } catch (e) {
              addToast(
                e?.response?.data?.message || 'Chat system error unexpected'
              )
            }
          }}
        />
      )} */}
      {activeSocialLinks.length > 0 &&
        privacy !== Constants.privacyType.private && (
          <View style={styles.footerButton}>
            {renderSocialItems(activeSocialLinks)}
          </View>
        )}
      {privacy === Constants.privacyType.private && (
        <View style={styles.footerButton}>
          <IconizedButton
            buttonTitle={t("key_close")}
            style={[
              styles.buttonStyle,
              {
                marginBottom: 16,
                width: "100%",
                paddingHorizontal: 20,
              },
            ]}
            filled
            onPress={async () => {
              onButtonAction(Constants.actionType.ACTION_CLOSE);
            }}
          />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    alignSelf: "stretch",
  },
  rowContainer: {
    alignItems: "center",
    flexDirection: "row",
  },
  contentContainer: {
    paddingTop: 36,
    paddingBottom: 16,
    alignSelf: "stretch",
    alignItems: "center",
  },
  text: {
    textAlign: "center",
    fontFamily: Fonts.primaryRegular,
    fontSize: 16,
    lineHeight: 14,
    color: getClass("text-white"),
  },
  socialLinkIcon: {
    width: 40,
    height: 40,
  },
  footerButton: {
    borderTopColor: getClass("border-tabBottomColor"),
    borderTopWidth: 1,
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: -16,
    paddingHorizontal: 16,
    alignSelf: "stretch",
    justifyContent: "center",
    height: 76,
  },
  footerItem: {
    paddingHorizontal: 6,
    flex: 1,
  },
  icon: {
    width: 14,
    height: 14,
  },
  label: {
    color: getClass("text-white"),
    fontFamily: Fonts.primaryMedium,
    fontSize: 16,
    lineHeight: 20,
  },
  emptyText: {
    fontSize: 18,
    lineHeight: 22,
    fontFamily: Fonts.primaryRegular,
    color: Colors.grayText,
    marginTop: 16,
  },
});

export default BookingContainer;
