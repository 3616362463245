import React, { useRef, useImperativeHandle, useState } from "react";
import { View } from "react-native-web";
import IDProfileModal from "./IDProfileModal";
import { Constants } from "../../../config";
// import ReportModalContainer from "../../../components/molecules/ReportModal";
import ContactModal from "./ContactModal";
import CalendarModal from "./CalendarModal";
import BookingModal from "./BookingModal";
import ReportMistakeModal from "./ReportMistakeModal";
import ShareModal from "./ShareModal";
// import AddTicketLinkModal from "./AddTicketLinkModal";
// import ReportSuccessModal from "../../../components/molecules/ReportModal/modals/ReportSuccessModal";
// import AddToCalendarModal from "./AddToCalendarModal";

const ProfileModalContainer = React.forwardRef((props, ref) => {
  const idProfileModalRef = useRef(null);
  const reportModalRef = useRef(null);
  const contactModalRef = useRef(null);
  const calendarModalRef = useRef(null);
  const bookingModalRef = useRef(null);
  const reportMistakeModalRef = useRef(null);
  const shareModalRef = useRef(null);
  const addTicketModalRef = useRef(null);
  const reportSuccessRef = useRef(null);
  const addToCalendarModalRef = useRef(null);
  const [data, setData] = useState({});
  const { item, category, isMobileVersion } = props;
  const contact = item ? item.contact : null;
  useImperativeHandle(ref, () => ({
    showModal: onShowModal,
    hideModal: onHideModal,
  }));
  const onShowModal = (modalType, _data = {}) => {
    if (modalType == Constants.profileModalType.PROFILE_MODAL_ID_PROFILE) {
      idProfileModalRef.current.showModal();
    }
    if (modalType == Constants.profileModalType.PROFILE_MODAL_REPORT) {
      if (_data) {
        setData(_data);
      }
      reportModalRef.current.showModal();
    }
    if (modalType == Constants.profileModalType.PROFILE_MODAL_CONTACT) {
      contactModalRef.current.showModal();
    }
    if (modalType == Constants.profileModalType.PROFILE_MODAL_CALENDAR) {
      calendarModalRef.current.showModal();
    }
    if (modalType == Constants.profileModalType.PROFILE_MODAL_BOOKING) {
      bookingModalRef.current.showModal();
    }
    if (modalType == Constants.profileModalType.PROFILE_MODAL_REPORT_MISTAKE) {
      reportMistakeModalRef.current.showModal();
    }
    if (modalType == Constants.profileModalType.PROFILE_MODAL_SHARE) {
      shareModalRef.current.showModal();
    }
    if (modalType == Constants.profileModalType.PROFILE_MODAL_ADD_TICKET_LINK) {
      addTicketModalRef.current.showModal();
    }
    if (modalType == Constants.profileModalType.PROFILE_MODAL_ADD_TO_CALENDAR) {
      addToCalendarModalRef.current.showModal();
    }
  };
  const onHideModal = (modalType) => {
    if (modalType == Constants.profileModalType.PROFILE_MODAL_ID_PROFILE) {
      idProfileModalRef.current.hideModal();
    }
  };

  return (
    <View>
      <IDProfileModal
        ref={idProfileModalRef}
        item={item}
        category={category}
        isMobileVersion={isMobileVersion}
      />
      {/* <ReportModalContainer ref={reportModalRef} item={item} {...data} /> */}
      <ReportMistakeModal
        ref={reportMistakeModalRef}
        isMobileVersion={isMobileVersion}
      />

      <ContactModal
        ref={contactModalRef}
        item={item}
        contact={contact}
        category={category}
        isMobileVersion={isMobileVersion}
      />

      <CalendarModal
        ref={calendarModalRef}
        item={item}
        category={category}
        isMobileVersion={isMobileVersion}
      />

      <BookingModal
        ref={bookingModalRef}
        item={item}
        contact={contact}
        category={category}
        isMobileVersion={isMobileVersion}
      />

      <ShareModal
        ref={shareModalRef}
        item={item}
        isMobileVersion={isMobileVersion}
      />

      {/* <AddTicketLinkModal ref={addTicketModalRef} item={item} /> */}

      {/* <ReportSuccessModal
        ref={reportSuccessRef}
        reportCategory={Constants.reportCategory.REPORT_ACCOUNT}
      /> */}
      {/* <AddToCalendarModal ref={addToCalendarModalRef} item={item} /> */}
    </View>
  );
});

export default ProfileModalContainer;
