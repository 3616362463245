import { Constants } from "../../../config";

export default {
  userMenu: [
    {
      id: 1,
      title: "Activity",
      tabType: Constants.profileTabType.TAB_ACTIVITY,
    },
    {
      id: 3,
      title: "Media",
      tabType: Constants.profileTabType.TAB_MEDIA,
    },
  ],
  eventMenu: [
    {
      id: 1,
      title: "About",
      tabType: Constants.profileTabType.TAB_ABOUT,
    },

    {
      id: 3,
      title: "Media",
      tabType: Constants.profileTabType.TAB_MEDIA,
    },
    {
      id: 4,
      title: "Lineup",
      tabType: Constants.profileTabType.TAB_LINEUP,
    },
    // {
    //   id: 5,
    //   title: "Wall",
    //   tabType: Constants.profileTabType.TAB_WALL,
    // },
    // {
    //   id: 5,
    //   title: "Memories",
    //   tabType: Constants.profileTabType.TAB_MEMORIES,
    // },
  ],
  artistMenu: [
    {
      id: 1,
      title: "About",
      tabType: Constants.profileTabType.TAB_ABOUT,
    },
    {
      id: 2,
      title: "Media",
      tabType: Constants.profileTabType.TAB_MEDIA,
    },
    {
      id: 3,
      title: "Festivals",
      tabType: Constants.profileTabType.TAB_EVENT,
    },
    // {
    //   id: 4,
    //   title: "Wall",
    //   tabType: Constants.profileTabType.TAB_WALL,
    // },
    // {
    //   id: 5,
    //   title: "Memories",
    //   tabType: Constants.profileTabType.TAB_MEMORIES,
    // },
  ],
  venueMenu: [
    {
      id: 1,
      title: "About",
      tabType: Constants.profileTabType.TAB_ABOUT,
    },
    {
      id: 3,
      title: "Media",
      tabType: Constants.profileTabType.TAB_MEDIA,
    },
    // {
    //   id: 4,
    //   title: "Events",
    //   tabType: Constants.profileTabType.TAB_EVENT,
    // },
    // {
    //   id: 5,
    //   title: "Wall",
    //   tabType: Constants.profileTabType.TAB_WALL,
    // },
    // {
    //   id: 6,
    //   title: "Memories",
    //   tabType: Constants.profileTabType.TAB_MEMORIES,
    // },
  ],
};
