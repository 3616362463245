import React, { useEffect, useState, useRef } from "react";
import { Colors, Constants, Fonts, Images } from "../../../../config";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native-web";
import { useTranslation } from "react-i18next";
import { CImage, DotSplitedText } from "../../../../components/atoms";
import { formatNumber } from "../../../../helpers";
import { AlertModal } from "../../../../components/molecules";
import dynamic from "next/dynamic";

const CAvatar = dynamic(() => import("../../../../components/atoms/CAvatar"), {
  ssr: false,
});

const ArtistInfoView = (props) => {
  const { t } = useTranslation();
  const { isMobileVersion } = props;
  const [isReady, setIsReady] = useState(false);
  const alertModalGenreRef = useRef(null);
  const alertModalLocationRef = useRef(null);

  useEffect(() => {
    setIsReady(true);
  }, []);

  if (!props.item || !isReady) return null;
  const {
    id,
    isFollow,
    profileId,
    isVirtual,
    isInPerson,
    title,
    location,
    startDate,
    periodString,
    totalFollower,
    totalFollowing,
    totalConnection,
    avatar,
    displayName,
    username,
    rating,
    userType,
    activity,
    bio,
    nationality,
    genres,
  } = props.item;
  let genreItems = [];
  if (genres) {
    genreItems = genres;
  }
  const userTypeName = "Artist";
  const genreName = genreItems.length > 0 ? genreItems[0].title : null;
  const moreGenre =
    genreItems.length > 1 ? "+" + (genreItems.length - 1) : null;
  const fullGenreNames = genreItems
    .map((genreItem) => genreItem.title)
    .join(", ");
  const artistTypeTexts = [userTypeName];
  const isInTour = activity == Constants.artistActivity.ACTIVITY_IN_TOUR;
  if (isInTour) {
    artistTypeTexts.push(t("key_artist_profile_in_tour"));
  }
  const hasNationality = nationality != null && nationality != "";
  const profileStatusItems = [
    {
      value: formatNumber(totalFollower || 0),
      label: t("key_profile_status_followers"),
      type: Constants.profileStatusType.PROFILE_STATUS_FOLLOWER,
    },
    {
      value: formatNumber(totalFollowing || 0),
      label: t("key_profile_status_followings"),
      type: Constants.profileStatusType.PROFILE_STATUS_FOLLOWING,
    },
    {
      value: formatNumber(totalConnection || 0),
      label: t("key_profile_status_connections"),
      type: Constants.profileStatusType.PROFILE_STATUS_CONNECTION,
    },
  ];

  return (
    <View style={[styles.container, props.style]}>
      <View
        style={
          isMobileVersion
            ? {}
            : {
                alignSelf: "center",
                maxWidth: Constants.PROFILE_PAGE_WIDTH,
                width: "100%",
              }
        }>
        {isMobileVersion ? (
          <View>
            <View style={styles.userInfoContainer}>
              <CAvatar
                url={avatar || "Local:defaultArtist"}
                size={70}
                alt={`${displayName} Avatar`}
              />
              <View
                style={[
                  styles.nameContainer,
                  isMobileVersion
                    ? { alignItems: "flex-start", marginLeft: 16 }
                    : {},
                ]}>
                <View
                  style={{
                    flexDirection: "row",
                    marginTop: 4,
                    paddingRight: 40,
                  }}>
                  <Text
                    style={[
                      styles.displayName,
                      isMobileVersion
                        ? {
                            fontSize: 16,
                            lineHeight: 19.5,
                          }
                        : {},
                    ]}>
                    {displayName}
                  </Text>
                  {/** <Image source={Images.iconVerifiedBadge} style={styles.badgeIcon} /> */}

                  {/* <BarRatingView
                    rating={rating}
                    style={{ marginLeft: 6, width: 17, height: 17 }}
                    maxHeight={14.41}
                    barStyle={{
                      marginLeft: 1.64,
                      width: 1.75,
                      borderRadius: 0.8,
                    }}
                  /> */}
                </View>

                <Text
                  style={[
                    styles.username,
                    { marginTop: 4 },
                    isMobileVersion
                      ? {
                          fontSize: 10,
                          lineHeight: 18,
                        }
                      : {},
                  ]}>
                  @{username}
                </Text>
                <View
                  style={[
                    styles.rowContainer,
                    { alignItems: "center", marginTop: 4 },
                  ]}>
                  <View style={{ width: 18, height: 18, marginRight: 2 }}>
                    <CImage
                      source={Images.iconSearchArtist}
                      style={styles.icon}
                      height={18}
                      width={18}
                      layout="fixed"
                      resizeMode="contain"
                    />
                  </View>
                  <DotSplitedText
                    texts={artistTypeTexts}
                    textStyle={{
                      color: Colors.white,
                      lineHeight: 20,
                      fontSize: 12,
                      fontFamily: Fonts.primaryRegular,
                    }}
                    dotStyle={{ backgroundColor: Colors.white }}
                  />
                </View>
              </View>
            </View>
            <Text style={styles.bio}>{bio}</Text>
            <View
              style={[
                styles.rowContainer,
                {
                  marginTop: 5,
                  marginHorizontal: 16,
                  justifyContent: "space-between",
                },
              ]}>
              <View style={styles.rowContainer}>
                <View style={{ width: 14, height: 14, marginRight: 4 }}>
                  <CImage source={Images.iconTagMusic} width={14} height={14} />
                </View>
                <Text
                  style={[
                    styles.text,
                    isMobileVersion
                      ? {
                          fontSize: 12,
                          lineHeight: 20,
                        }
                      : {},
                  ]}>
                  {genreName}
                </Text>
                {moreGenre && (
                  <TouchableOpacity
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      paddingHorizontal: 8,
                      borderWidth: 1,
                      height: 22,
                      borderRadius: 11,
                      marginLeft: 4,
                      borderColor: Colors.spliterLine,
                    }}
                    onPress={() => {
                      alertModalGenreRef.current.alert("Music", fullGenreNames);
                    }}>
                    <Text
                      style={[
                        styles.boldText,
                        isMobileVersion
                          ? {
                              fontSize: 10,
                              lineHeight: 12,
                            }
                          : {},
                      ]}>
                      {moreGenre}
                    </Text>
                  </TouchableOpacity>
                )}
              </View>
              <TouchableOpacity
                style={styles.rowContainer}
                onPress={() =>
                  nationality !== "" && nationality !== null
                    ? alertModalLocationRef.current.alert(
                        "Location",
                        nationality
                      )
                    : null
                }>
                <View style={{ width: 14, height: 14, marginRight: 4 }}>
                  <CImage
                    source={Images.iconLocationPin}
                    width={12}
                    height={12}
                  />
                </View>
                <Text
                  style={[
                    styles.text,
                    isMobileVersion
                      ? {
                          fontSize: 12,
                          lineHeight: 20,
                        }
                      : {},
                  ]}>
                  <address style={{ fontStyle: "normal" }}>
                    {nationality}
                  </address>
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        ) : (
          <View>
            <CAvatar
              url={avatar || "Local:defaultArtist"}
              size={130}
              style={{ alignSelf: "center", marginTop: -65 }}
              alt={`${displayName} Avatar`}
            />
            <View style={{ alignSelf: "center" }}>
              <View style={styles.nameContainer}>
                <View
                  style={{
                    flexDirection: "row",
                    marginTop: 25,
                    alignItems: "center",
                  }}>
                  <Text style={styles.displayName}>
                    <h1>{displayName}</h1>
                  </Text>
                  {/** <Image source={Images.iconVerifiedBadge} style={styles.badgeIcon} /> */}

                  {/* <BarRatingView
                    rating={rating}
                    style={{
                      width: 20,
                      height: 20,
                      position: "absoute",
                      right: -12,
                      top: 0,
                    }}
                    maxHeight={20}
                    barStyle={{
                      marginLeft: 1.07,
                      width: 3.21,
                      borderRadius: 3.1,
                    }}
                  /> */}
                </View>

                <Text style={[styles.username, { marginTop: 13 }]}>
                  @{username}
                </Text>
                <View
                  style={[
                    styles.rowContainer,
                    { alignItems: "center", marginTop: 15 },
                  ]}>
                  <CImage
                    source={Images.iconSearchArtist}
                    style={styles.icon}
                    height={20}
                    width={20}
                    layout="fixed"
                    resizeMode="contain"
                  />
                  <DotSplitedText
                    texts={artistTypeTexts}
                    textStyle={{
                      color: Colors.white,
                      lineHeight: 20,
                      fontSize: 16,
                      fontFamily: Fonts.primaryRegular,
                    }}
                    dotStyle={{
                      backgroundColor: Colors.white,
                      width: 3,
                      height: 3,
                      borderRadius: 3,
                    }}
                    style={{ marginLeft: 4 }}
                  />
                </View>
              </View>
            </View>

            <View
              style={[
                styles.rowContainer,
                {
                  marginTop: 5,
                  marginHorizontal: 16,
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                },
              ]}>
              <View style={styles.rowContainer}>
                <CImage
                  width={20}
                  height={20}
                  layout="fixed"
                  source={Images.iconTagMusic}
                  style={[styles.locationPin]}
                />
                <Text
                  style={[
                    styles.text,
                    { marginLeft: 8 },
                    isMobileVersion
                      ? {
                          fontSize: 12,
                          lineHeight: 20,
                        }
                      : {},
                  ]}>
                  {genreName}
                </Text>
                {moreGenre && (
                  <TouchableOpacity
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      paddingHorizontal: 8,
                      borderWidth: 1,
                      height: 25,
                      borderRadius: 25,
                      marginLeft: 8,
                      borderColor: Colors.spliterLine,
                    }}
                    onPress={() => {
                      alertModalGenreRef.current.alert("Music", fullGenreNames);
                    }}>
                    <Text style={styles.boldText}>{moreGenre}</Text>
                  </TouchableOpacity>
                )}
              </View>
              <Text style={styles.bio}>{bio}</Text>

              <TouchableOpacity
                style={[styles.rowContainer]}
                disabled={!hasNationality}
                onPress={() => {
                  if (hasNationality)
                    alertModalLocationRef.current.alert(
                      "Location",
                      nationality
                    );
                }}>
                <CImage
                  width={20}
                  height={20}
                  layout="fixed"
                  source={Images.iconLocationPin}
                  style={styles.locationPin}
                />
                <Text
                  style={[
                    styles.text,
                    { marginLeft: 5 },
                    isMobileVersion
                      ? {
                          fontSize: 12,
                          lineHeight: 20,
                        }
                      : {},
                  ]}>
                  <address style={{ fontStyle: "normal" }}>
                    {nationality}
                  </address>
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        )}

        {/* <ProfileStatus
          items={profileStatusItems}
          style={{ marginTop: isMobileVersion ? 16 : 35 }}
          onPressItem={(item) => {
            // navigation.navigate("NetworkScreen", {
            //   type: item.type,
            //   profileId: profileId,
            //   category: Constants.categoryType.CATEGORY_ARTIST,
            // });
          }}
          isMobileVersion={isMobileVersion}
        /> */}

        <AlertModal
          icon={Images.iconTagMusic}
          ref={alertModalGenreRef}
          isMobileVersion={isMobileVersion}
          isSmall={true}
        />
        <AlertModal
          icon={Images.iconLocationPin}
          ref={alertModalLocationRef}
          isMobileVersion={isMobileVersion}
          isSmall={true}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: "stretch",
    backgroundColor: Colors.black,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
  },
  userInfoContainer: {
    alignSelf: "stretch",
    flexDirection: "row",
    marginHorizontal: 16,
    marginTop: 15,
  },
  displayName: {
    fontSize: 28,
    lineHeight: 32,
    fontFamily: Fonts.primaryBold,
    color: "white",
  },
  durationContainer: {
    marginTop: 16,
    marginHorizontal: 16,
    marginBottom: 15,
  },
  rowContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  locationPin: {
    width: 12,
    height: 12,
    marginRight: 4,
  },
  text: {
    color: "white",
    fontSize: 16,
    lineHeight: 20,
    fontFamily: Fonts.primaryRegular,
  },
  boldText: {
    color: "white",
    fontSize: 14,
    lineHeight: 17,
    fontFamily: Fonts.primaryBold,
  },
  locationText: {},
  tagContainer: {
    height: 20,
    paddingHorizontal: 6,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    backgroundColor: Colors.grayBackground,
  },
  tagText: {
    fontSize: 16,
    lineHeight: 18,
    color: Colors.whiteText,
  },
  eventButtonContainer: {
    borderRadius: 12,
    height: 24,
    borderWidth: 1,
    borderColor: Colors.spliterLine,
    alignItems: "center",
    justifyContent: "center",
    width: 104,
    flexDirection: "row",
  },
  eventButtonText: {
    fontFamily: Fonts.primaryBold,
    fontSize: 16,
    lineHeight: 18,
    color: "white",
  },
  eventIcon: {
    width: 11,
    height: 11,
    marginRight: 4,
  },
  badgeIcon: {
    marginLeft: 8,
    width: 10,
    height: 10,
  },
  nameContainer: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
  },
  username: {
    fontFamily: Fonts.primaryRegular,
    fontSize: 16,
    lineHeight: 18,
    color: Colors.placeholder,
  },
  icon: {
    width: 19,
    height: 19,
  },
  bio: {
    marginLeft: 16,
    marginTop: 8,
    fontFamily: Fonts.primaryRegular,
    fontSize: 16,
    lineHeight: 20,
    color: Colors.white,
    maxWidth: 340,
    textAlign: "center",
  },
});

export default ArtistInfoView;
