import React, { useEffect, useCallback, useState, useRef } from "react";
import { Colors, Constants, Fonts, Images } from "../../../../config";
import { StyleSheet, View, ScrollView } from "react-native";
import { useTranslation } from "react-i18next";
import ProfileHeader from "../../components/ProfileHeader";
import ArtistInfoView from "../components/ArtistInfoView";
import ProfileBottomView from "../../components/ProfileBottomView";
import { CImage, IconizedButton } from "../../../../components/atoms";
import ProfileModalContainer from "../../modals/ProfileModalContainer";
import { useRouter } from "next/router";
import ContactModal from "../../modals/ContactModal";
import { TouchableOpacity } from "react-native-web";
import Blur from "react-blur";
import ProfileBottomMenuBar from "../../components/ProfileBottomMenuBar";
import { NextSeo } from "next-seo";
import { generateURLSlug, getArrayToString } from "../../../../../helpers";
import MobileArtistInfoView from "../components/MobileArtistInfoView";
import { capitalizeFirstLetter } from "../../../../helpers/formatHelpers";
import Head from "next/head";

const ArtistProfileView = (props) => {
  const { t } = useTranslation();
  const navigation = useRouter();
  const modalRef = useRef(null);
  const contactModalRef = useRef(null);
  const [isShowMenu, setIsShowMenu] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const { tabType, subTabType, isMobileVersion } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const PROFILE_COVER_HEADER_HEIGHT = isMobileVersion ? 239 : 573;
  const PROFILE_COVER_IMAGE_HEIGHT = isMobileVersion ? 239 : 450;
  const PROFILE_PANEL_BORDER_RADIUS = isMobileVersion ? 25 : 123;
  const items = isMobileVersion
    ? [
        {
          id: 3,
          title: t("key_id_profile"),
          icon: Images.iconBarcode,
          type: Constants.profileMenuType.PROFILE_MENU_ID_PROFILE,
        },
        {
          id: 13,
          title: t("key_modal_title_share"),
          icon: Images.iconShareColored,
          type: Constants.profileMenuType.PROFILE_MENU_SHARE,
        },
        /*{
      id: 6,
      title: t("key_report"),
      icon: Images.iconReport,
      type: Constants.profileMenuType.PROFILE_MENU_REPORT,
    },*/
      ]
    : [
        {
          id: 3,
          title: t("key_id_profile"),
          icon: Images.iconBarcode,
          type: Constants.profileMenuType.PROFILE_MENU_ID_PROFILE,
        },
        /*{
      id: 6,
      title: t("key_report"),
      icon: Images.iconReport,
      type: Constants.profileMenuType.PROFILE_MENU_REPORT,
    },*/
      ];
  const onPressMenuItem = (item) => {
    if (item.type == Constants.profileMenuType.PROFILE_MENU_ID_PROFILE) {
      modalRef.current.showModal(
        Constants.profileModalType.PROFILE_MODAL_ID_PROFILE
      );
    } else if (item.type == Constants.profileMenuType.PROFILE_MENU_REPORT) {
      modalRef.current.showModal(
        Constants.profileModalType.PROFILE_MODAL_REPORT,
        {
          reportCategory: Constants.reportCategory.REPORT_ARTIST,
        }
      );
    } else if (item.type == Constants.profileMenuType.PROFILE_MENU_SHARE) {
      modalRef.current.showModal(
        Constants.profileModalType.PROFILE_MODAL_SHARE
      );
    }
  };
  let coverPic = "Local:imagePlaceholder";
  if (props.artist.coverPic) {
    coverPic = props.artist.coverPic;
  } else if (props.artist.avatar) {
    coverPic = props.artist.avatar;
  }

  const onChangeIsShown = (flag) => {
    setIsShowMenu(flag);
  };
  let photos = props?.artist?.photos;
  if (photos && !Array.isArray(photos)) {
    photos = JSON.parse(photos);
  }
  const backgroundImage =
    photos?.length > 0 ? photos[0].url : "Local:imagePlaceholder";
  const canonicalURL = `https://${
    process.env.NEXT_PUBLIC_WWW_BASE_URL
  }${generateURLSlug(
    props?.artist?.id,
    props?.artist?.displayName,
    null,
    Constants.categoryType.CATEGORY_ARTIST,
    tabType === "about" ? "about" : `about#${tabType}`
  )}`;

  useEffect(() => {
    setIsReady(true);
  }, []);

  return (
    <View style={styles.container}>
      <Head>
        <script
          key="structured-data"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "WebPage",
              "@id": canonicalURL,
              url: canonicalURL,
              name: props?.artist?.displayName,
              datePublished: props?.artist?.createdAt,
              dateModified: props?.artist?.updatedAt,
              image: [props?.artist?.avatar]
                .filter((i) => i !== null)
                .map((i) => ({ "@id": i })),
            }),
          }}
        />
      </Head>
      <NextSeo
        noindex={true}
        nofollow={false}
        title={`${props?.artist?.displayName}${
          tabType !== "about" ? " " + capitalizeFirstLetter(tabType) : ""
        } - ${process.env.NEXT_PUBLIC_APP_NAME}`}
        description={`Discover ${props?.artist?.displayName} ${
          tabType === "media"
            ? `media gallery on ${process.env.NEXT_PUBLIC_APP_NAME}.`
            : tabType === "wall"
            ? `community on ${process.env.NEXT_PUBLIC_APP_NAME} - posts about info, news and much more.`
            : tabType === "memories"
            ? `reviews on ${process.env.NEXT_PUBLIC_APP_NAME}.`
            : tabType === "festivals"
            ? `festivals on ${process.env.NEXT_PUBLIC_APP_NAME} - dates, location and much more.`
            : `profile on ${process.env.NEXT_PUBLIC_APP_NAME} - events, tickets and much more.` //else about
        }`}
        canonical={canonicalURL}
        openGraph={{
          type: "website",
          locale: "en_EN",
          url: canonicalURL,
          title: `${props?.artist?.displayName}${
            tabType !== "about" ? " " + capitalizeFirstLetter(tabType) : ""
          } - ${process.env.NEXT_PUBLIC_APP_NAME}`,
          description: `Discover ${props?.artist?.displayName} ${
            tabType === "media"
              ? `media gallery on ${process.env.NEXT_PUBLIC_APP_NAME}.`
              : tabType === "wall"
              ? `community on ${process.env.NEXT_PUBLIC_APP_NAME} - posts about info, news and much more.`
              : tabType === "memories"
              ? `reviews on ${process.env.NEXT_PUBLIC_APP_NAME}.`
              : tabType === "festivals"
              ? `festivals on ${process.env.NEXT_PUBLIC_APP_NAME} - dates, location and much more.`
              : `profile on ${process.env.NEXT_PUBLIC_APP_NAME} - events, tickets and much more.` //else about
          }`,
          images: [
            {
              url:
                props?.artist?.photos && props?.artist?.photos?.length > 0
                  ? props?.artist?.photos
                    ? JSON.parse(props?.artist?.photos)[0]?.url
                    : ""
                  : props?.artist?.avatar ||
                    `https://${process.env.NEXT_PUBLIC_WWW_BASE_URL}/images/${
                      process.env.NEXT_PUBLIC_APP_NAME === "Zoopedia" ||
                      process.env.NEXT_PUBLIC_APP_NAME === "PrefabWorld" ||
                      process.env.NEXT_PUBLIC_APP_NAME === "Parkful" ||
                      process.env.NEXT_PUBLIC_APP_NAME === "WorldCasino" ||
                      process.env.NEXT_PUBLIC_APP_NAME === "Castlepedia" ||
                      process.env.NEXT_PUBLIC_APP_NAME === "LuxResort Club" ||
                      process.env.NEXT_PUBLIC_APP_NAME === "AparthotelClub" ||
                      process.env.NEXT_PUBLIC_APP_NAME === "RooftopClub" ||
                      process.env.NEXT_PUBLIC_APP_NAME === "Cocktayl" ||
                      process.env.NEXT_PUBLIC_APP_NAME === "SpaClub" ||
                      process.env.NEXT_PUBLIC_APP_NAME === "Beachful"
                        ? process.env.NEXT_PUBLIC_APP_NAME?.replace(
                            " ",
                            ""
                          )?.toLowerCase() + "/"
                        : ""
                    }image_placeholder_new.png`,
              width: 1200,
              height: 630,
              alt: `${props?.artist?.displayName}${
                tabType !== "about" ? " " + capitalizeFirstLetter(tabType) : ""
              } - ${process.env.NEXT_PUBLIC_APP_NAME}`,
              type: "image/png",
            },
          ],
          siteName: `${process.env.NEXT_PUBLIC_APP_NAME}${process.env.NEXT_PUBLIC_APP_SLOGAN}`,
        }}
      />
      <ScrollView
        style={[styles.container]}
        contentContainerStyle={styles.scrollView}>
        <View
          style={{
            alignSelf: "stretch",
            height: PROFILE_COVER_HEADER_HEIGHT,
          }}>
          {isReady && <CImage objectFit={"fill"} imgUrl={backgroundImage} />}
          <div
            className="bg-white/2 absolute bottom-0 left-0 right-0 top-0"
            style={{ backdropFilter: "blur(40px)" }}
          />
          <ProfileHeader
            photos={
              props?.artist?.photos && props?.artist?.photos?.length > 0
                ? props?.artist?.photos || []
                : [{ url: "Local:imagePlaceholder" }]
            }
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            items={items}
            artist={props.artist}
            onPressMenuItem={onPressMenuItem}
            isShowMenu={isShowMenu}
            onChangeIsShown={onChangeIsShown}
            style={{
              maxWidth: Constants.PROFILE_PAGE_WIDTH,
              width: "100%",
              height: PROFILE_COVER_IMAGE_HEIGHT,
              marginBottom: isMobileVersion ? 0 : PROFILE_PANEL_BORDER_RADIUS,
              alignSelf: "center",
            }}
            isMobileVersion={isMobileVersion}
            category={Constants.categoryType.CATEGORY_ARTIST}
          />
        </View>
        {isMobileVersion ? (
          <ArtistInfoView
            style={{
              marginTop: -1 * PROFILE_PANEL_BORDER_RADIUS,
              borderTopLeftRadius: PROFILE_PANEL_BORDER_RADIUS,
              borderTopRightRadius: PROFILE_PANEL_BORDER_RADIUS,
            }}
            item={props.artist}
            loadData={props.loadData}
            openContact={() => contactModalRef.current.showModal()}
            isMobileVersion={isMobileVersion}
          />
        ) : (
          <ArtistInfoView
            style={{
              marginTop: -1 * PROFILE_PANEL_BORDER_RADIUS,
              borderTopLeftRadius: PROFILE_PANEL_BORDER_RADIUS,
              borderTopRightRadius: PROFILE_PANEL_BORDER_RADIUS,
            }}
            item={props.artist}
            loadData={props.loadData}
            openContact={() => contactModalRef.current.showModal()}
            isMobileVersion={isMobileVersion}
          />
        )}

        {isMobileVersion ? (
          <ProfileBottomView
            style={{
              // marginTop: -25,
              maxWidth: Constants.PROFILE_PAGE_WIDTH,
              width: "100%",
              alignSelf: "center",
            }}
            item={props.artist}
            category={Constants.categoryType.CATEGORY_ARTIST}
            isNested
            initialTabType={tabType}
            subTabType={subTabType}
            isMobileVersion={isMobileVersion}
          />
        ) : (
          <ProfileBottomView
            style={{
              alignSelf: "center",
              maxWidth: Constants.PROFILE_PAGE_WIDTH,
              width: "100%",
            }}
            item={props.artist}
            category={Constants.categoryType.CATEGORY_ARTIST}
            isNested
            initialTabType={tabType}
            subTabType={subTabType}
            isMobileVersion={isMobileVersion}
          />
        )}

        <ProfileModalContainer
          ref={modalRef}
          item={props.artist}
          category={Constants.categoryType.CATEGORY_ARTIST}
          isMobileVersion={isMobileVersion}
        />
      </ScrollView>
      {/* <Shadow style={styles.bottomContainer}> */}

      <ProfileBottomMenuBar
        profile={props.artist}
        isMobileVersion={isMobileVersion}
        category={Constants.categoryType.CATEGORY_ARTIST}
        hideContact={true}
        hideShare={isMobileVersion}
        onMorePress={() => {
          setIsShowMenu(true);
        }}
        onContactPress={() =>
          modalRef.current.showModal(
            Constants.profileModalType.PROFILE_MODAL_CONTACT
          )
        }
        onSharePress={() =>
          modalRef.current.showModal(
            Constants.profileModalType.PROFILE_MODAL_SHARE
          )
        }
        renderButton={() => {
          return (
            <IconizedButton
              buttonTitle={t("key_button_calendar")}
              url={`${generateURLSlug(
                props?.artist?.id,
                props?.artist?.displayName,
                null,
                Constants.categoryType.CATEGORY_ARTIST,
                "calendar"
              )}`}
              filled
              fontSize={isMobileVersion ? 12 : 16}
              iconStyle={{ width: 18, height: 18 }}
              icon={Images.iconCalendarColored}
              style={
                isMobileVersion
                  ? { height: 32, width: "100%" }
                  : { height: 40, width: 320 }
              }
              isMobileVersion={isMobileVersion}
              isHeading
            />
          );
        }}
      />
      {/* </Shadow> */}
      <ContactModal
        ref={contactModalRef}
        item={props.artist}
        contact={props.artist?.contact}
        category={Constants.categoryType.CATEGORY_ARTIST}
        isMobileVersion={isMobileVersion}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.black,
    marginBottom: 0,
  },
  headerStyle: {
    height: 290,
    width: "100%",
  },
  bottomContainer: {
    height: 90,
    alignSelf: "stretch",
    backgroundColor: Colors.grayBackground,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    marginBottom: 50,
    paddingHorizontal: 30,
    justifyContent: "center",
  },
  scrollView: {
    flexGrow: 1,
    flexDirection: "column",
  },
  moreButton: {
    borderWidth: 1,
    borderColor: Colors.spliterLine,
    borderRadius: 20,
    padding: 5,
  },
});

export default ArtistProfileView;
