import React, { useRef } from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native-web";
import { useTranslation } from "react-i18next";
import { Fonts, Colors, Constants, Images } from "../../../../config";
import { CImage, IconizedButton } from "../../../../components/atoms";

const ShareContainer = (props) => {
  const { t } = useTranslation();
  const textAreaRef = useRef(null);
  const { isMobileVersion } = props;
  const { id, profileId, title, displayName, privacy } = props.item;

  const currentURL = window.location.href?.replace(
    "http://localhost:3000",
    `https://${process.env.NEXT_PUBLIC_WWW_BASE_URL}`
  );
  const socialLinks = [
    {
      icon: Images.iconFacebookLinkCircle,
      alt: "facebook",
      url: `https://www.facebook.com/sharer/sharer.php?u=${currentURL}`,
    },
    {
      icon: Images.iconTwitterLinkCircle,
      alt: "twitter",
      url: `https://twitter.com/intent/tweet?text=${
        title || displayName
      }&url=${currentURL}`,
    },
    {
      icon: Images.iconWhatsappCircle,
      alt: "whatsapp",
      url: `https://api.whatsapp.com/send?text=${
        title || displayName
      } ${currentURL}`,
    },
    {
      icon: Images.iconGmailCircle,
      alt: "email",
      url: `mailto:?body=${currentURL}&subject=${title || displayName}`,
    },
    {
      icon: Images.iconLinkedinCircle,
      alt: "linkedin",
      url: `https://www.linkedin.com/shareArticle/?mini=true&url=${currentURL}&title=${
        process.env.NEXT_PUBLIC_APP_NAME
      }&summary=${title || displayName}`,
    },
    {
      icon: Images.iconLinkCircle,
      alt: "copy",
      url: "copy",
    },
  ];
  const onButtonAction = (actionType) => {
    if (props.onButtonAction) {
      props.onButtonAction({
        modalType: Constants.profileModalType.PROFILE_MODAL_CONTACT,
        actionType: actionType,
      });
    }
  };
  const onOpenLink = (url) => {
    const win = window.open(url, "_blank");
    win.focus();
  };

  const copyTextToClipboard = async (e) => {
    textAreaRef.current.select();
    document.execCommand("copy");
    // if ("clipboard" in navigator) {
    //   return await navigator.clipboard.writeText(text);
    // } else {
    //   return document.execCommand("copy", true, text);
    // }
  };

  const renderSocialLinkItem = (item, index, totalCount) => {
    const { url, alt, icon } = item;
    return (
      <TouchableOpacity
        style={[
          styles.socialLinkIcon,
          {
            flex: 1,
            alignItems: "center",
            marginVertical: 10,
          },
        ]}
        key={index + "social-link"}
        onPress={() => {
          if (url === "copy") {
            copyTextToClipboard();
          } else {
            onOpenLink(url);
          }
          onButtonAction(Constants.actionType.ACTION_CLOSE);
        }}>
        <CImage
          width={40}
          height={40}
          layout="fixed"
          source={icon}
          style={styles.socialLinkIcon}
          resizeMode="contain"
          alt={alt}
        />
      </TouchableOpacity>
    );
  };
  const renderSocialItems = (socialItems) => {
    const totalCount = socialItems.length;
    return socialItems.map((socialItem, index) => {
      return renderSocialLinkItem(socialItem, index, totalCount);
    });
  };
  return (
    <View style={[styles.container, props.style]}>
      {socialLinks.length > 0 && privacy !== Constants.privacyType.private && (
        <View
          style={[
            styles.footerButton,
            {
              borderWidth: 0,
              marginTop: 16,
              flexWrap: "wrap",
              justifyContent: "flex-start",
              height: "auto",
            },
          ]}>
          {renderSocialItems(socialLinks)}
        </View>
      )}
      {privacy === Constants.privacyType.private && (
        <View style={styles.footerButton}>
          <IconizedButton
            buttonTitle={t("key_close")}
            style={[
              styles.buttonStyle,
              {
                marginBottom: 16,
                width: "100%",
                paddingHorizontal: 20,
              },
            ]}
            filled
            onPress={async () => {
              onButtonAction(Constants.actionType.ACTION_CLOSE);
            }}
          />
        </View>
      )}
      <textarea
        ref={textAreaRef}
        value={window.location.href}
        className="opacity-0 absolute top-0 left-0"
        readOnly={true}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    alignSelf: "stretch",
  },
  rowContainer: {
    alignItems: "center",
    flexDirection: "row",
  },
  contentContainer: {
    paddingTop: 20,
    paddingBottom: 16,
    alignSelf: "stretch",
    alignItems: "center",
  },
  text: {
    textAlign: "center",
    fontFamily: Fonts.primaryRegular,
    fontSize: 16,
    lineHeight: 14,
    color: Colors.white,
  },
  socialLinkIcon: {
    width: 40,
    height: 40,
  },
  footerButton: {
    borderTopColor: Colors.tabBottomColor,
    borderTopWidth: 1,
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: -16,
    paddingHorizontal: 16,
    alignSelf: "stretch",
    justifyContent: "center",
    height: 76,
  },
  footerItem: {
    paddingHorizontal: 6,
    flex: 1,
  },
  icon: {
    width: 14,
    height: 14,
  },
  label: {
    color: Colors.white,
    fontFamily: Fonts.primaryMedium,
    fontSize: 16,
    lineHeight: 20,
  },
  emptyText: {
    fontSize: 18,
    lineHeight: 22,
    fontFamily: Fonts.primaryRegular,
    color: Colors.grayText,
    marginTop: 16,
  },
});

export default ShareContainer;
