import React from 'react'
import { useTranslation } from 'react-i18next'
import { CModal } from '../../../../components/atoms'
import { Constants, Images } from '../../../../config'
import ReportMistakeContainer from './ReportMistakeContainer'

const ReportMistakeModal = React.forwardRef((props, ref) => {
  const { t } = useTranslation()
  const onButtonAction = (data) => {
    if (props.onButtonAction) {
      props.onButtonAction(data)
    }
    if (ref) {
      ref.current.hideModal()
    }
  }
  return (
    <CModal
      ref={ref}
      {...props}
      title={t('key_report_mistake')}
      icon={Images.iconEdit}>
      <ReportMistakeContainer {...props} onButtonAction={onButtonAction} />
    </CModal>
  )
})
export default ReportMistakeModal
