import React from "react";
import { Constants } from "../../config";
import EventProfileViewContainer from "./event/containers/EventProfileViewContainer";
import ArtistProfileViewContainer from "./artist/containers/ArtistProfileViewContainer";
import VenueProfileViewContainer from "./venue/containers/VenueProfileViewContainer";
// import UserProfileViewContainer from "./user/containers/UserProfileViewContainer";

const ProfileScreen = (props) => {
  const renderContent = () => {
    const { category } = props;
    if (category == Constants.categoryType.CATEGORY_ARTIST) {
      return <ArtistProfileViewContainer {...props} />;
    } else if (
      category == Constants.categoryType.CATEGORY_VENUE ||
      category == Constants.categoryType.CATEGORY_ROOFTOP ||
      category == Constants.categoryType.CATEGORY_SPA ||
      category == Constants.categoryType.CATEGORY_VENUE_BEACHFUL ||
      category == Constants.categoryType.CATEGORY_VENUE_COCKTAIL ||
      category === Constants.categoryType.CATEGORY_PREFAB_COMPANY ||
      category === Constants.categoryType.CATEGORY_PREFAB_HOMES ||
      category === Constants.categoryType.CATEGORY_ANIMAL ||
      category === Constants.categoryType.CATEGORY_ZOO ||
      category === Constants.categoryType.CATEGORY_PARKFUL ||
      category === Constants.categoryType.CATEGORY_CASINO ||
      category === Constants.categoryType.CATEGORY_CASTLE ||
      category === Constants.categoryType.CATEGORY_RESORT ||
      category === Constants.categoryType.CATEGORY_APARTHOTEL
    )
      return <VenueProfileViewContainer {...props} />;
    else if (
      category == Constants.categoryType.CATEGORY_USER ||
      category == Constants.categoryType.CATEGORY_OTHER
    )
      return null;
    // return <UserProfileViewContainer {...props} />;
    return <EventProfileViewContainer {...props} />;
  };

  return <div className="flex flex-col flex-1 bg-black">{renderContent()}</div>;
};

export default ProfileScreen;
