import React, { useEffect, useState, useRef } from "react";
import { Colors, Constants, Fonts, Images } from "../../../../config";
import { StyleSheet, View, Text } from "react-native-web";
import { useTranslation } from "react-i18next";
import DurationView from "./DurationView";

import { CImage } from "../../../../components/atoms";
import { calculateMilisecondDiff, formatNumber } from "../../../../helpers";
import { formatPeriod, getClass } from "../../../../helpers/formatHelpers";
import { AlertModal } from "../../../../components/molecules";
import EventStatusView from "./EventStatusView";
import OfficialBadgeModal from "../../modals/OfficialBadgeModal";

const TagView = (props) => {
  const { isMobileVersion } = props;
  return (
    <div>
      {isMobileVersion ? (
        <View
          style={[
            styles.tagContainer,
            props.style,
            { marginTop: 0, height: 20 },
          ]}>
          <Text
            style={[styles.tagText, { fontSize: 10 }]}
            numberOfLines={1}
            ellipsizeMode="tail">
            {props.title}
          </Text>
        </View>
      ) : (
        <View style={[styles.tagContainer, props.style, { marginTop: 0 }]}>
          <Text style={styles.tagText} numberOfLines={1} ellipsizeMode="tail">
            {props.title}
          </Text>
        </View>
      )}
    </div>
  );
};

const EventInfoView = (props) => {
  const { t } = useTranslation();
  const [showPopupOfficial, setShowPopupOfficial] = useState(false);
  const alertModalRef = useRef(null);
  const officialBadgeModalRef = useRef(null);
  const { isMobileVersion } = props;
  const {
    id,
    isVirtual,
    isInPerson,
    title,
    startDate,
    endDate,
    totalSaved,
    totalGoing,
    totalConnection,
    profileId,
    isSaving,
    isGoing,
    status,
    timezoneData,
    isOfficial,
    isPro,
  } = props.item;

  const location =
    // (props.item.location || props.item.city) + //remove venue
    // props.item.city + // only city and country
    // (props.item.nationality ? ", " + props.item.nationality : "");
    props.item.city; // only city
  const [durationInMiliseconds, setDurationInMiliseconds] = useState(
    calculateMilisecondDiff(new Date(), startDate)
  );
  const periodString = formatPeriod(
    startDate,
    endDate,
    Constants.dateFormat.DATE_FORMAT_LONG,
    timezoneData?.label?.match(/\((.*?)\)/)[0]
  );
  const isShowDuration = status == 1;
  const isShowStatusString = status > 1;
  const isTBC =
    status === Constants.festivalStatus.FE_STAT_UPCOMMING_TO_BE_UPDATED;
  const isReschedule =
    status === Constants.festivalStatus.FE_STAT_RESCHEDULE_TO_BE_UPDATED;

  const eventStatusItems = [
    {
      value: formatNumber(totalSaved),
      label: t("key_saved"),
      type: Constants.profileStatusType.PROFILE_STATUS_SAVED,
    },
    {
      value: formatNumber(totalGoing),
      label: t("key_going"),
      type: Constants.profileStatusType.PROFILE_STATUS_GOING,
    },
    {
      value: formatNumber(totalConnection),
      label: t("key_connections"),
      type: Constants.profileStatusType.PROFILE_STATUS_CONNECTION,
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setDurationInMiliseconds(calculateMilisecondDiff(new Date(), startDate));
    }, 1000);

    return () => clearInterval(interval);
  }, [id]);

  if (!props.item) return null;

  return (
    <View style={[styles.container, props.style]}>
      <View
        style={{
          alignSelf: "center",
          maxWidth: Constants.PROFILE_PAGE_WIDTH,
          width: "100%",
        }}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Text
            style={[
              styles.title,
              isMobileVersion
                ? { alignSelf: "start", fontSize: 18, marginTop: 18 }
                : {},
            ]}>
            <h1>{title}</h1>
          </Text>
          {(isOfficial === 1 || isPro === 1) && (
            <View
              onMouseOver={
                isMobileVersion ? null : () => setShowPopupOfficial(true)
              }
              onMouseLeave={
                isMobileVersion ? null : () => setShowPopupOfficial(false)
              }
              onClick={
                isMobileVersion
                  ? () => officialBadgeModalRef.current.showModal()
                  : null
              }
              style={{
                marginLeft: -10,
                marginTop: -1,
                position: "relative",
                cursor: isMobileVersion ? "pointer" : "default",
              }}>
              <CImage
                source={Images.iconVerifiedBadge}
                alt="badge official"
                width={14}
                height={14}
                layout="fixed"
              />
              {showPopupOfficial && !isMobileVersion && (
                <View
                  style={{
                    position: "absolute",
                    width: isMobileVersion ? 260 + 24 : 336 + 24,
                    height: isMobileVersion ? 140 : 182,
                    zIndex: 10,
                    left: 0,
                    top: -91,
                    paddingLeft: 24,
                  }}>
                  <View
                    style={{
                      backgroundColor: getClass("bg-gray1"),
                      width: isMobileVersion ? 260 : 336,
                      height: isMobileVersion ? 140 : 182,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingHorizontal: 22,
                      borderRadius: 18,
                      width: "100%",
                      height: "100%",
                      boxShadow: "2px 2px 10px rgba(0,0,0,0.5)",
                    }}>
                    <CImage
                      source={Images.iconVerifiedBadgeMedium}
                      alt="verified account"
                      width={isMobileVersion ? 24 : 35}
                      height={isMobileVersion ? 24 : 35}
                      layout="fixed"
                    />
                    <Text
                      style={{
                        fontFamily: Fonts.primaryBold,
                        fontSize: isMobileVersion ? 16 : 20,
                        lineHeight: isMobileVersion ? 20 : 24,
                        color: Colors.white,
                        marginVertical: isMobileVersion ? 9 : 14,
                        textAlign: "center",
                      }}>
                      Official Account
                    </Text>
                    <Text
                      style={{
                        fontFamily: Fonts.primaryRegular,
                        fontSize: isMobileVersion ? 12 : 16,
                        lineHeight: isMobileVersion ? 16 : 18,
                        color: Colors.white80,
                        textAlign: "center",
                      }}>
                      This is an official account verified by{" "}
                      {process.env.NEXT_PUBLIC_APP_NAME}
                    </Text>
                  </View>
                </View>
              )}
            </View>
          )}
        </View>

        {isShowDuration && (
          <div className="sm:px-0 px-4">
            {isMobileVersion ? (
              <DurationView
                duration={durationInMiliseconds}
                style={[
                  styles.durationContainer,
                  { marginTop: 16, marginBottom: 16 },
                ]}
                isMobileVersion={isMobileVersion}
              />
            ) : (
              <DurationView
                duration={durationInMiliseconds}
                style={styles.durationContainer}
                isMobileVersion={isMobileVersion}
              />
            )}
          </div>
        )}

        {isShowStatusString && (
          <div className="sm:px-0 px-4">
            <EventStatusView
              eventStatus={status}
              style={[
                styles.durationContainer,
                isMobileVersion ? { marginTop: 16, marginBottom: 16 } : {},
              ]}
              isMobileVersion={isMobileVersion}
            />
          </div>
        )}

        {/* location icon */}

        <div className="flex sm:flex-row flex-col sm:items-center items-start justify-between">
          {isMobileVersion ? (
            <View style={[styles.rowContainer, { paddingHorizontal: 16 }]}>
              <CImage
                source={Images.iconLocationPin}
                style={styles.locationPin}
                objectFit={"contain"}
                height={14}
                width={14}
                layout="fixed"
              />
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: 10,
                }}>
                {!isTBC && !isReschedule && (
                  <Text
                    style={[styles.text, styles.locationText, { fontSize: 10 }]}
                    numberOfLines={1}
                    ellipsizeMode="tail">
                    <address style={{ fontStyle: "normal" }}>
                      {location}
                    </address>
                  </Text>
                )}
                {isVirtual && (
                  <TagView
                    title={t("key_virtual")}
                    style={{ marginLeft: 13 }}
                    isMobileVersion={isMobileVersion}
                  />
                )}
                {isInPerson && (
                  <TagView
                    title={t("key_in_person")}
                    style={{ marginLeft: 13 }}
                    isMobileVersion={isMobileVersion}
                  />
                )}
              </View>
            </View>
          ) : (
            <View style={[styles.rowContainer]}>
              <CImage
                source={Images.iconLocationPin}
                style={styles.locationPin}
                objectFit={"contain"}
                height={20}
                width={20}
                layout="fixed"
              />
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  alignItems: "center",
                  marginLeft: 10,
                }}>
                {!isTBC && !isReschedule && (
                  <Text
                    style={[styles.text, styles.locationText]}
                    numberOfLines={1}
                    ellipsizeMode="tail">
                    <address style={{ fontStyle: "normal" }}>
                      {location}
                    </address>
                  </Text>
                )}
                {isVirtual && (
                  <TagView
                    title={t("key_virtual")}
                    style={{ marginLeft: 13 }}
                  />
                )}
                {isInPerson && (
                  <TagView
                    title={t("key_in_person")}
                    style={{ marginLeft: 13 }}
                  />
                )}
              </View>
            </View>
          )}

          {isMobileVersion ? (
            <View
              style={[
                styles.rowContainer,
                { marginTop: 10, paddingHorizontal: 16 },
              ]}>
              <CImage
                source={Images.iconBooking}
                style={styles.locationPin}
                width={14}
                height={14}
                layout="fixed"
                objectFit={"contain"}
              />

              {!isTBC && !isReschedule && (
                <Text
                  style={[
                    styles.text,
                    styles.locationText,
                    { marginLeft: 10, fontSize: 10 },
                  ]}>
                  <time>{periodString}</time>
                </Text>
              )}
            </View>
          ) : (
            <View style={[styles.rowContainer]}>
              <CImage
                source={Images.iconBooking}
                style={styles.locationPin}
                width={20}
                height={20}
                layout="fixed"
                objectFit={"contain"}
              />

              {!isTBC && !isReschedule && (
                <Text
                  style={[
                    styles.text,
                    styles.locationText,
                    { marginLeft: 10 },
                  ]}>
                  <time>{periodString}</time>
                </Text>
              )}
            </View>
          )}
        </div>

        <AlertModal icon={Images.iconCalendarColored} ref={alertModalRef} />
        <OfficialBadgeModal
          ref={officialBadgeModalRef}
          isMobileVersion={isMobileVersion}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: "stretch",
    backgroundColor: Colors.black,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
  },
  title: {
    fontSize: 28,
    lineHeight: 32,
    marginTop: 32,
    paddingHorizontal: 16,
    fontFamily: Fonts.primaryBold,
    color: "white",
    alignSelf: "center",
  },
  statusText: {
    fontSize: 16,
    color: "white",
    fontFamily: Fonts.primaryRegular,
  },
  durationContainer: {
    marginTop: 25,
    maxWidth: Constants.PROFILE_PAGE_WIDTH,
    width: "100%",
    alignSelf: "center",
    marginBottom: 24,
  },

  rowContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  locationPin: {
    // width: 14,
    // height: 14,
    // marginRight: 6,
  },
  text: {
    color: "white",
    fontSize: 16,
    lineHeight: 18,
    fontFamily: Fonts.primaryRegular,
  },
  locationText: {},
  tagContainer: {
    height: 30,
    paddingHorizontal: 10,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 20,
    backgroundColor: Colors.grayBackground,
  },
  tagText: {
    fontSize: 16,
    lineHeight: 20,
    fontFamily: Fonts.primaryRegular,
    color: Colors.whiteText,
  },
  eventButtonContainer: {
    borderRadius: 12,
    height: 24,
    borderWidth: 1,
    borderColor: Colors.spliterLine,
    alignItems: "center",
    justifyContent: "center",
    width: 104,
    flexDirection: "row",
  },
  buttonContainer: {
    borderRadius: 12,
    height: 24,
    borderWidth: 1,
    borderColor: Colors.spliterLine,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    paddingHorizontal: 8,
  },
  eventButtonText: {
    fontFamily: Fonts.primaryBold,
    fontSize: 16,
    lineHeight: 18,
    color: "white",
  },
  eventIcon: {
    width: 11,
    height: 11,
    marginRight: 4,
  },
  buttonTitle: {
    fontFamily: Fonts.primaryRegular,
    fontSize: 16,
    lineHeight: 18,
    color: "white",
  },
  buttonTitleHighlight: {
    fontFamily: Fonts.primaryBold,
    fontSize: 16,
    lineHeight: 18,
    color: "white",
  },
});

export default EventInfoView;
