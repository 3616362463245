import React from "react";
import { useTranslation } from "react-i18next";
import { CModal } from "../../../../components/atoms";
import { Constants, Images } from "../../../../config";
import CalendarContainer from "./CalendarContainer";

const CalendarModal = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { isMobileVersion } = props;
  const onButtonAction = (data) => {
    if (props.onButtonAction) {
      props.onButtonAction(data);
    }
    if (ref) {
      ref.current.hideModal();
    }
  };
  return (
    <CModal
      ref={ref}
      {...props}
      modalType={Constants.modalType.MODAL_TYPE_CENTER}
      closableWithOutsideTouch
      // hideClose
      title={t("key_modal_title_calendar")}
      icon={Images.iconCalendar}
      iconWidth={isMobileVersion ? 20 : 28}
      iconHeight={isMobileVersion ? 20 : 28}
      iconStyle={isMobileVersion ? null : { marginTop: -2 }}>
      <CalendarContainer
        {...props}
        onButtonAction={onButtonAction}
        isMobileVersion={isMobileVersion}
      />
    </CModal>
  );
});
export default CalendarModal;
