import React, { useState, useEffect } from "react";
import { View, StyleSheet, TouchableOpacity, Text } from "react-native-web";
import { RWebShare } from "react-web-share";
import { CImage } from "../../../components/atoms";
import { Colors, Constants, Fonts, Images } from "../../../config";
import { getClass } from "../../../helpers/formatHelpers";

const ProfileBottomMenuBar = (props) => {
  const isFloating = true;
  const { profile, category, isMobileVersion, hideContact, hideShare } = props;
  const title = profile?.title || profile?.displayName || "";

  const renderButton = () => {
    if (props.renderButton) {
      return props.renderButton();
    }
    return null;
  };
  const renderMagicButton = () => {
    if (props.renderMagicButton) {
      return props.renderMagicButton();
    }
    return null;
  };
  return (
    <View
      style={[
        {
          height: isMobileVersion ? 62 : 80,
          marginTop: -30,
          justifyContent: "space-between",
          flexDirection: "row",
          maxWidth: Constants.PROFILE_PAGE_WIDTH,
          width: "100%",
          alignSelf: "center",
          paddingHorizontal: 30,
          borderTopLeftRadius: isMobileVersion ? 25 : 40,
          borderTopRightRadius: isMobileVersion ? 25 : 40,
          alignItems: "center",
          backgroundColor: getClass("footer"),
          filter: "drop-shadow(0px -2px 15px rgba(0, 0, 0, 0.5))",
        },
        isFloating && { position: "fixed", bottom: 0 },
      ]}>
      {renderButton()}
      {/* {renderMagicButton()} */}

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginLeft:
            isMobileVersion &&
            category !== Constants.categoryType.CATEGORY_FESTIVAL
              ? hideContact
                ? 0
                : 8
              : 0,
        }}>
        {!hideContact && (
          <TouchableOpacity
            onPress={() => {
              if (props.onContactPress) {
                props.onContactPress();
              }
            }}
            style={[
              styles.contactButton,
              isMobileVersion
                ? { marginRight: 8, height: 34, width: "auto" }
                : {},
            ]}>
            <CImage
              width={isMobileVersion ? 16 : 20}
              height={isMobileVersion ? 16 : 20}
              layout="fixed"
              objectFit={"cover"}
              source={Images.iconChat}
              alt="contact"
            />
            <Text
              style={[
                styles.contactButtonText,
                isMobileVersion ? { fontSize: 12 } : {},
                { textTransform: "uppercase" },
              ]}>
              <h2>Contact</h2>
            </Text>
          </TouchableOpacity>
        )}
        {!hideShare && (
          <TouchableOpacity
            style={{
              display: "flex",
              ...getClass("btn-border2"),
              borderRadius: 20,
              alignItems: "center",
              justifyContent: "center",
              width: isMobileVersion ? 32 : 40,
              height: isMobileVersion ? 32 : 40,
              marginRight: isMobileVersion ? 0 : 15,
            }}
            onPress={() => {
              if (props.onSharePress) {
                props.onSharePress();
              }
            }}>
            <CImage
              width={isMobileVersion ? 16 : 24}
              height={isMobileVersion ? 16 : 24}
              layout="fixed"
              objectFit={"cover"}
              source={Images.iconShare}
              style={{ width: 24, height: 24 }}
              alt="share"
            />
          </TouchableOpacity>
        )}

        {!isMobileVersion && (
          <TouchableOpacity
            onPress={() => {
              if (props.onMorePress) {
                props.onMorePress();
              }
            }}
            style={styles.moreButton}>
            <CImage
              width={24}
              height={24}
              layout="fixed"
              objectFit={"cover"}
              source={Images.iconMore}
              style={{ width: 24, height: 24 }}
              alt="more menu"
            />
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignSelf: "stretch",
  },
  moreButton: {
    ...getClass("btn-border2"),
    borderRadius: 20,
    alignItems: "center",
    justifyContent: "center",
    width: 40,
    height: 40,
  },
  contactButton: {
    ...getClass("btn-border2"),
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 20,
    height: 40,
    flexDirection: "row",
    alignItems: "center",
    marginRight: 15,
    width: 210,
  },
  contactButtonText: {
    fontFamily: Fonts.primaryBold,
    fontSize: 16,
    lineHeight: 20,
    color: getClass("text-white"),
    textAlign: "center",
    marginLeft: 5,
  },
});

export default ProfileBottomMenuBar;
