import React, { useEffect, useState } from "react";
import EventProfileView from "../views/EventProfileView";
import { EventDao } from "../../../../DAO";

const EventProfileViewContainer = (props) => {
  const { id, profileId, tabType, subTabType, item, lineup } = props;
  const [detail, setDetail] = useState(item);

  const loadData = () => {
    if (id) {
      EventDao.findOne(id).then((result) => {
        setDetail(result);
      });
    } else if (profileId) {
      EventDao.findOneByProfileId(profileId).then((result) => {
        setDetail(result);
      });
    }
  };

  useEffect(() => {
    loadData();
  }, [props.category, props.id, props.profileId]);

  return item ? (
    <EventProfileView
      event={item}
      lineup={lineup}
      loadData={loadData}
      tabType={tabType}
      subTabType={subTabType}
      category={props.category}
      isMobileVersion={props.isMobileVersion}
    />
  ) : null;
};

export default EventProfileViewContainer;
