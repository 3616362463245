import React from "react";
import { StyleSheet, View, Text, Linking, Alert } from "react-native-web";
import { useTranslation } from "react-i18next";
import { Fonts, Colors, Constants } from "../../../../config";
import { getClass } from "../../../../helpers/formatHelpers";
const ReportMistakeContainer = (props) => {
  const { t } = useTranslation();
  const { isMobileVersion } = props;
  const onOpenLink = async (url) => {
    setTimeout(async () => {
      const supported = await Linking.canOpenURL(url);

      if (supported) {
        await Linking.openURL(url);
      } else {
        Alert.alert(`Don't know how to open this URL: ${url}`);
      }
    }, 400);
  };
  return (
    <View style={[styles.container, props.style]}>
      <View style={styles.contentContainer}>
        <Text
          style={[styles.text, isMobileVersion ? { fontSize: 12 } : {}]}
          onPress={() =>
            onOpenLink(
              `mailto:report.mistake@${process.env.NEXT_PUBLIC__BASE_URL}`
            )
          }>
          report.mistake@supervybe.com
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    alignSelf: "stretch",
  },
  contentContainer: {
    paddingVertical: 16,
    alignSelf: "stretch",
  },
  text: {
    textAlign: "center",
    fontFamily: Fonts.primaryRegular,
    fontSize: 16,
    lineHeight: 20,
    color: getClass("text-white"),
  },
});

export default ReportMistakeContainer;
