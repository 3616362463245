import React from "react";
import { useTranslation } from "react-i18next";
import { CModal } from "../../../../components/atoms";
import { Constants, Images } from "../../../../config";
import OfficialBadgeContainer from "./OfficialBadgeContainer";

const OfficialBadgeModal = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const onButtonAction = (data) => {
    if (props.onButtonAction) {
      props.onButtonAction(data);
    }
    if (ref) {
      ref.current.hideModal();
    }
  };
  return (
    <CModal ref={ref} {...props}>
      <OfficialBadgeContainer {...props} onButtonAction={onButtonAction} />
    </CModal>
  );
});
export default OfficialBadgeModal;
