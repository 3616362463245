import React, { useState } from "react";
import { StyleSheet, View } from "react-native-web";
import { useTranslation } from "react-i18next";
import { Fonts, Colors } from "../../../../config";
import { CTabSelector } from "../../../../components/atoms";
import { uniqBy } from "lodash";

const CalendarContainer = (props) => {
  const { t } = useTranslation();
  const { isMobileVersion, category, item } = props;
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const tabList = [
    {
      id: 1,
      title: "Hours",
    },

    {
      id: 3,
      title: "Year",
    },
  ];
  const { calendar } = item;

  let venueCalendarTimes = [
    { weekday: "Monday", isActive: false, childs: [] },
    { weekday: "Tuesday", isActive: false, childs: [] },
    { weekday: "Wednesday", isActive: false, childs: [] },
    { weekday: "Thursday", isActive: false, childs: [] },
    { weekday: "Friday", isActive: false, childs: [] },
    { weekday: "Saturday", isActive: false, childs: [] },
    { weekday: "Sunday", isActive: false, childs: [] },
  ];
  let venueMonth = [
    { month: "January", isActive: false },
    { month: "February", isActive: false },
    { month: "March", isActive: false },
    { month: "April", isActive: false },
    { month: "May", isActive: false },
    { month: "June", isActive: false },
    { month: "July", isActive: false },
    { month: "August", isActive: false },
    { month: "September", isActive: false },
    { month: "October", isActive: false },
    { month: "November", isActive: false },
    { month: "December", isActive: false },
  ];

  calendar.forEach((item) => {
    venueCalendarTimes.forEach((item2) => {
      if (item.weekday === item2.weekday) {
        if (item.startTime !== "") {
          item2.isActive = item.isActive;
          venueCalendarTimes
            .find((i) => i.weekday === item2.weekday)
            ?.childs?.push({
              weekday: item2.weekday,
              startTime: item.startTime,
              endTime: item.endTime,
            });
        }
      } else {
        venueCalendarTimes
          .find((i) => i.weekday === item2.weekday)
          ?.childs?.push({
            weekday: item2.weekday,
            startTime: "",
            endTime: "",
          });
      }
    });
    venueMonth.forEach((item2) => {
      if (item.month === item2.month) {
        item2.isActive = item.isActive;
      }
    });
  });

  venueCalendarTimes = venueCalendarTimes.map((item) => {
    return {
      ...item,
      childs: uniqBy(item.childs, "startTime"),
    };
  });

  // Filter out empty startTime objects from weekdays where any other weekday has a non-empty startTime
  venueCalendarTimes = venueCalendarTimes.map((item) => {
    if (
      venueCalendarTimes.some(
        (weekday) =>
          weekday.weekday !== item.weekday &&
          weekday.childs.some((child) => child.startTime !== "")
      )
    ) {
      item.childs = item.childs.filter((child) => child.startTime !== "");
    }
    return item;
  });

  // Check if the childs array is empty and add an object with empty startTime and endTime
  venueCalendarTimes = venueCalendarTimes.map((item) => {
    if (item.childs.length === 0) {
      item.childs.push({
        isActive: false,
        weekday: item.weekday,
        startTime: "",
        endTime: "",
      });
    }
    return item;
  });

  const onSelectTab = (item, index) => {
    setSelectedTabIndex(index);
  };

  return (
    <View style={[styles.container, props.style]}>
      <CTabSelector
        tabItemType="bottom-line-tab"
        items={tabList}
        showInView
        selectedIndex={selectedTabIndex}
        onSelectTab={onSelectTab}
        containerStyle={styles.tabContainer}
      />
      {selectedTabIndex === 0 && (
        <div className="p-[20px] flex flex-col items-center">
          {venueCalendarTimes.map((item, index) => (
            <div
              key={index}
              className="text-white sm:text-base text-sm font-medium">
              {item.weekday?.substring(0, 3)?.toUpperCase()}
              &nbsp;&nbsp;
              {!item.isActive ? (
                <span className="text-grayText2 uppercase">Closed</span>
              ) : (
                item.childs.map((item2, index2) => (
                  <span key={index2} className="text-grayText2">
                    {item2.startTime?.substring(0, 5)}-
                    {item2.endTime?.substring(0, 5)}
                    {index2 < item.childs.length - 1 ? (
                      <span className="text-white"> - </span>
                    ) : (
                      ""
                    )}
                  </span>
                ))
              )}
            </div>
          ))}
        </div>
      )}
      {selectedTabIndex === 1 && (
        <div className="p-[20px] flex flex-row flex-wrap gap-0 items-center justify-center">
          <div className="w-[45%]">
            {venueMonth
              .filter((i, index) => index < 6)
              .map((item, index) => (
                <div
                  key={index}
                  className="text-white sm:text-base text-sm font-medium flex items-center justify-center">
                  {item.month?.toUpperCase() == "JUNE"
                    ? "JUNE"
                    : item.month?.substring(0, 3)?.toUpperCase()}
                  &nbsp;&nbsp;
                  <span className="text-grayText2 uppercase">
                    {!item.isActive ? "Closed" : "Open"}
                  </span>
                </div>
              ))}
          </div>
          <div className="w-[45%]">
            {venueMonth
              .filter((i, index) => index >= 6)
              .map((item, index) => (
                <div
                  key={index}
                  className="text-white sm:text-base text-sm font-medium flex items-center justify-center">
                  {item.month?.toUpperCase() == "JUNE"
                    ? "JUNE"
                    : item.month?.substring(0, 3)?.toUpperCase()}
                  &nbsp;&nbsp;
                  <span className="text-grayText2 uppercase">
                    {!item.isActive ? "Closed" : "Open"}
                  </span>
                </div>
              ))}
          </div>
        </div>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    alignSelf: "stretch",
  },
  rowContainer: {
    alignItems: "center",
    flexDirection: "row",
  },
  contentContainer: {
    paddingTop: 36,
    paddingBottom: 16,
    alignSelf: "stretch",
    alignItems: "center",
  },
  text: {
    textAlign: "center",
    fontFamily: Fonts.primaryRegular,
    fontSize: 16,
    lineHeight: 14,
    color: Colors.white,
  },
  socialLinkIcon: {
    width: 40,
    height: 40,
  },
  footerButton: {
    borderTopColor: Colors.tabBottomColor,
    borderTopWidth: 1,
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: -16,
    paddingHorizontal: 16,
    alignSelf: "stretch",
    justifyContent: "center",
    height: 76,
  },
  footerItem: {
    paddingHorizontal: 6,
    flex: 1,
  },
  icon: {
    width: 14,
    height: 14,
  },
  label: {
    color: Colors.white,
    fontFamily: Fonts.primaryMedium,
    fontSize: 16,
    lineHeight: 20,
  },
  emptyText: {
    fontSize: 18,
    lineHeight: 22,
    fontFamily: Fonts.primaryRegular,
    color: Colors.grayText,
    marginTop: 16,
  },
});

export default CalendarContainer;
