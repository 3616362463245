import React, { useState } from "react";
import ArtistProfileView from "../views/ArtistProfileView";
import { ArtistDao } from "../../../../DAO";

const ArtistProfileViewContainer = (props) => {
  const { artistEvents, isMobileVersion } = props;
  const { id, profileId, tabType, subTabType, item } = props;
  const [detail, setDetail] = useState(item);

  const loadData = () => {
    if (id) {
      ArtistDao.findOne(id).then((result) => {
        setDetail(result);
      });
    } else if (profileId) {
      ArtistDao.findOneByProfileId(profileId).then((result) => {
        setDetail(result);
      });
    }
  };

  // useEffect(() => {
  //     loadData();
  // }, [props.category, props.id, props.profileId]);

  return detail ? (
    <ArtistProfileView
      artist={detail}
      loadData={loadData}
      tabType={tabType}
      subTabType={subTabType}
      artistEvents={artistEvents}
      isMobileVersion={isMobileVersion}
    />
  ) : null;
};

export default ArtistProfileViewContainer;
