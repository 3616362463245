import React, { useEffect, useState } from "react";
import { Colors, Constants, Fonts } from "../../../config";
import { StyleSheet, View, Text } from "react-native-web";
import { useTranslation } from "react-i18next";
import profileBottomTabData from "./profileBottomTabData";
import { useRouter } from "next/router";
import CTab from "../../../components/atoms/CTab";
import { generateURLSlug } from "../../../../helpers";
import { getClass } from "../../../helpers/formatHelpers";
import dynamic from "next/dynamic";

const ProfileAbout = dynamic(() => import("./ProfileAbout"), {
  ssr: true,
});
const MediaListContainer = dynamic(
  () => import("../../media/MediaListContainer"),
  {
    ssr: false,
  }
);
const LineupContainer = dynamic(() => import("../../lineup"), {
  ssr: false,
});
const InsightContainer = dynamic(
  () => import("../../insight/InsightContainer"),
  {
    ssr: false,
  }
);
const EventContainer = dynamic(() => import("../../event"), {
  ssr: false,
});
const CompanyHomeScreen = dynamic(
  () => import("../../event/CompanyHomeScreen"),
  {
    ssr: false,
  }
);

const ProfileBottomView = (props) => {
  const {
    isNested,
    item,
    category,
    initialTabIndex,
    initialTabType,
    subTabType,
    isAccount,
    isMobileVersion,
    venueEvents,
  } = props;

  let items = [];
  if (
    category === Constants.categoryType.CATEGORY_EVENT ||
    category === Constants.categoryType.CATEGORY_FESTIVAL
  ) {
    items = profileBottomTabData.eventMenu;
  } else if (category === Constants.categoryType.CATEGORY_ARTIST) {
    items = profileBottomTabData.artistMenu;
  } else if (
    category === Constants.categoryType.CATEGORY_VENUE ||
    category === Constants.categoryType.CATEGORY_ROOFTOP ||
    category === Constants.categoryType.CATEGORY_SPA ||
    category === Constants.categoryType.CATEGORY_VENUE_BEACHFUL ||
    category === Constants.categoryType.CATEGORY_VENUE_COCKTAIL ||
    category === Constants.categoryType.CATEGORY_PREFAB_COMPANY ||
    category === Constants.categoryType.CATEGORY_PREFAB_HOMES ||
    category === Constants.categoryType.CATEGORY_ZOO ||
    category === Constants.categoryType.CATEGORY_ANIMAL ||
    category === Constants.categoryType.CATEGORY_PARKFUL ||
    category === Constants.categoryType.CATEGORY_CASINO ||
    category === Constants.categoryType.CATEGORY_CASTLE ||
    category === Constants.categoryType.CATEGORY_RESORT ||
    category === Constants.categoryType.CATEGORY_APARTHOTEL
  ) {
    items =
      venueEvents?.length > 0
        ? profileBottomTabData.venueMenu.concat([
            {
              id: 4,
              title: "Events",
              tabType: Constants.profileTabType.TAB_EVENT,
            },
          ])
        : // :
          // item?.companyHomes?.length > 0
          // ? profileBottomTabData.venueMenu.concat([
          //     {
          //       id: 4,
          //       title: "Homes",
          //       tabType: Constants.profileTabType.TAB_HOME,
          //     },
          //   ])
          profileBottomTabData.venueMenu;
  } else if (category === Constants.categoryType.CATEGORY_USER) {
    items = profileBottomTabData.userMenu;
  }

  let tabIndex = initialTabIndex;
  if (initialTabType) {
    tabIndex = items.findIndex((x) => x.tabType === initialTabType);
  }

  const [selectedTabIndex, setSelectedTabIndex] = useState(tabIndex || 0);
  const [selectedTabType, setSelectedTabType] = useState(
    initialTabType == undefined ? items[0].tabType : ""
  );

  let privacy = "public";
  if (item?.privacy && !isAccount) {
    privacy = item?.privacy;
  }

  useEffect(() => {
    changeTabType(initialTabType || "about");
  }, [initialTabType]);

  const { t } = useTranslation();
  const navigation = useRouter();
  if (!item) return null;

  const changeTabType = (tabType) => {
    const index = items.findIndex((x) => x.tabType == tabType);
    setSelectedTabIndex(index);
    setSelectedTabType(tabType);
  };
  const onChangeTabType = (tabType) => {
    navigation.replace({
      pathname: generateURLSlug(
        item.id,
        item.title || item.displayName || item.nickname,
        item.startDate,
        category,
        "about"
      ),
      hash: tabType === "about" ? null : tabType,
    });
    changeTabType(tabType);
  };

  const renderAbout = () => {
    if (isNested) {
      return (
        <ProfileAbout
          item={item}
          category={category}
          changeTabType={onChangeTabType}
          isMobileVersion={isMobileVersion}
        />
      );
    } else {
      return null;
    }
  };

  const renderContent = (tabItem) => {
    if (!tabItem) return null;
    const { tabType } = tabItem;
    if (tabType == Constants.profileTabType.TAB_ABOUT) {
      return renderAbout();
    }
    if (tabType == Constants.profileTabType.TAB_INSIGHT) {
      return (
        <InsightContainer
          item={item}
          category={category}
          tabType={selectedTabType}
          isMobileVersion={isMobileVersion}
        />
      );
    }
    if (tabType == Constants.profileTabType.TAB_EVENT) {
      return (
        <EventContainer
          item={item}
          category={category}
          tabType={selectedTabType}
          isMobileVersion={isMobileVersion}
        />
      );
    }
    if (tabType == Constants.profileTabType.TAB_HOME) {
      return (
        <CompanyHomeScreen
          items={item.companyHomes}
          category={category}
          isMobileVersion={isMobileVersion}
        />
      );
    }
    if (tabType == Constants.profileTabType.TAB_MEDIA) {
      return privacy === Constants.privacyType.private ? (
        <View style={{ padding: 20, alignItems: "center" }}>
          <Text
            style={{
              color: Colors.grayText,
              fontFamily: Fonts.primaryRegular,
              fontSize: 16,
              lineHeight: 20,
            }}>
            {t("key_private_mode_text")}
          </Text>
        </View>
      ) : (
        <MediaListContainer
          item={item}
          category={category}
          profileId={item.profileId}
          tabType={selectedTabType}
          subTabType={subTabType}
          isMobileVersion={isMobileVersion}
        />
      );
    }
    if (tabType == Constants.profileTabType.TAB_LINEUP) {
      return (
        <LineupContainer
          item={item}
          category={category}
          tabType={selectedTabType}
          isMobileVersion={isMobileVersion}
        />
      );
    }
    if (tabType == Constants.profileTabType.TAB_WALL) {
      return null;
      // <WallContainer
      //   item={item}
      //   category={category}
      //   tabType={selectedTabType}
      //   isMobileVersion={isMobileVersion}
      // />
    }
    if (tabType == Constants.profileTabType.TAB_MEMORIES) {
      return null;
      // <MemoryContainer
      //   item={item}
      //   category={category}
      //   tabType={selectedTabType}
      //   isMobileVersion={isMobileVersion}
      // />
    }
    if (tabType == Constants.profileTabType.TAB_ACTIVITY) {
      return privacy === Constants.privacyType.private ? (
        <View style={{ padding: 20, alignItems: "center" }}>
          <Text
            style={{
              color: Colors.grayText,
              fontFamily: Fonts.primaryRegular,
              fontSize: 16,
              lineHeight: 20,
            }}>
            {t("key_private_mode_text")}
          </Text>
        </View>
      ) : null;
      // <ActivityContainer
      //   item={item}
      //   category={category}
      //   tabType={selectedTabType}
      //   isMobileVersion={isMobileVersion}
      // />
    }

    return null;
  };

  const renderTabs = () => {
    return (
      <div className="relative flex flex-col min-w-0 break-words w-full mb-1 ">
        <div className="px-0 flex-auto">
          <div className="tab-content tab-space">
            {items.map((item, index) => {
              return (
                <div
                  key={index}
                  className={
                    selectedTabType === item.tabType ? "block" : "hidden"
                  }
                  id={"link" + index}>
                  {renderContent(item)}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <View
      style={[
        styles.container,
        isMobileVersion
          ? {
              borderTopLeftRadius: 25,
              borderTopRightRadius: 25,
              paddingBottom: 0,
            }
          : {},
        props.style,
      ]}>
      <CTab
        selectedTabIndex={selectedTabIndex}
        onChangeTab={(index) => {
          onChangeTabType(items[index]?.tabType);
        }}
        tabs={items}
        item={item}
        category={category}
        isMobileVersion={isMobileVersion}
        isHeadingAbout>
        {renderTabs()}
      </CTab>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    //alignSelf: "stretch",
    flexDirection: "column",
    paddingBottom: 25,
    backgroundColor: getClass("background"),
    overflow: "hidden",
  },
});

export default ProfileBottomView;
