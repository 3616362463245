import Link from "next/link";
import { useState } from "react";
import { generateURLSlug } from "../../../../helpers";
import { Colors, Fonts } from "../../../config";
import { getClass } from "../../../helpers/formatHelpers";

const CTab = (props) => {
  const {
    tabs,
    selectedTabIndex,
    item,
    category,
    isMobileVersion,
    isHeadingAbout,
  } = props;

  return (
    <div>
      <div className="flex flex-wrap">
        <div className="w-full">
          <div className="text-xs font-medium  text-center text-gray-500 dark:text-gray-400 ">
            <ul className="flex sm:flex-wrap -mb-px w-full mb-3">
              {tabs.map((tab, index) => {
                return (
                  <li key={index} className="flex-auto mr-0">
                    <Link
                      prefetch={false}
                      href={generateURLSlug(
                        item.id,
                        item.title || item.displayName || item.nickname,
                        item.startDate,
                        category,
                        `about#${tab.tabType}`
                      )}>
                      <a
                        className={
                          "w-full relative " +
                          (selectedTabIndex === index
                            ? `inline-block ${
                                isMobileVersion ? "p-4" : "p-6"
                              } w-full rounded-t-lg border-b ${getClass(
                                "border-gray"
                              )} dark:text-blue-500`
                            : `inline-block ${
                                isMobileVersion ? "p-4" : "p-6"
                              } w-full text-blue-600 rounded-t-lg border-b ${getClass(
                                "border-gray"
                              )} active dark:text-blue-500`)
                        }
                        data-toggle="tab"
                        onClick={(e) => {
                          e.preventDefault();

                          if (props.onChangeTab) {
                            props.onChangeTab(index);
                          }
                        }}
                        role="button"
                        aria-current="page"
                        style={
                          isMobileVersion
                            ? {
                                fontSize: 12,
                                fontFamily: getClass(
                                  "weight-tab-bold",
                                  false,
                                  selectedTabIndex === index
                                ),
                                color:
                                  selectedTabIndex === index
                                    ? getClass("text-white")
                                    : getClass("text-gray"),
                              }
                            : {
                                fontSize: 16,
                                height: 70,
                                fontFamily: getClass(
                                  "weight-tab-bold",
                                  false,
                                  selectedTabIndex === index
                                ),
                                color:
                                  selectedTabIndex === index
                                    ? getClass("text-white")
                                    : getClass("text-gray"),
                              }
                        }>
                        {isHeadingAbout && tab.title === "About" ? (
                          <h2>{tab.title}</h2>
                        ) : (
                          tab.title
                        )}
                        {selectedTabIndex === index && (
                          <div
                            className={`${getClass("btn-gradient2")}`}
                            style={{
                              position: "absolute",
                              bottom: -1.5,
                              left: 0,
                              width: "100%",
                              height: 2,
                              borderRadius: 1,
                            }}
                          />
                        )}
                      </a>
                    </Link>
                  </li>
                );
              })}
            </ul>

            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTab;
