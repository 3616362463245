import React from "react";
import VenueProfileView from "../views/VenueProfileView";

const VenueProfileViewContainer = (props) => {
  const {
    id,
    profileId,
    venueEvents,
    tabType,
    subTabType,
    category,
    item,
    lineup,
  } = props;

  return (
    <VenueProfileView
      venue={item}
      lineup={lineup}
      tabType={tabType}
      subTabType={subTabType}
      venueEvents={venueEvents}
      category={category}
      isMobileVersion={props.isMobileVersion}
    />
  );
};

export default VenueProfileViewContainer;
